import { ofType } from '@shared/utils/typing-utils';
import maplibregl, { LngLatBounds, PaddingOptions } from 'maplibre-gl';

export class MapUtils {
  public static fitBoundsForSource(map: maplibregl.Map, sourceId: string) {
    const features = map.querySourceFeatures(sourceId);
    const geometries = features.map(f => f.geometry);

    // Initialize an empty array for coordinates
    const bounds = new LngLatBounds();

    geometries.forEach(geom => {
      switch (geom.type) {
        case 'Polygon':
          geom.coordinates[0] // Do not consider 'holes' in the polygon
            .map(coord => new maplibregl.LngLat(coord[0], coord[1]))
            .forEach((coord) => {
              bounds.extend(coord);
            });
          return;
        case 'MultiPolygon':
          geom.coordinates.forEach(polygon => {
            polygon[0] // Do not consider 'holes' in the polygon
              .map(coord => new maplibregl.LngLat(coord[0], coord[1]))
              .forEach((coord) => {
                bounds.extend(coord);
              });
          });
          return;
        case 'Point':
        case 'MultiPoint':
        case 'LineString':
        case 'MultiLineString':
        case 'GeometryCollection':
        default:
        // Unsupported Type
      }
    });

    if (!bounds.isEmpty()) {
      const boundsPercentage = geometries.length === 1 ? 35 : 10;
      map.fitBounds(bounds, { padding: MapUtils.getPaddingAsPercentage(map, boundsPercentage), duration: 3000 });
    }
  }

  public static getPaddingAsPercentage(map: maplibregl.Map, percentage: number) {
    const mapWidth = map.getContainer().clientWidth - map.getPadding().left - map.getPadding().right;
    const mapHeight = map.getContainer().clientHeight - map.getPadding().top - map.getPadding().bottom;

    return ofType<PaddingOptions>({
      top: (mapHeight * percentage) / 100,
      bottom: (mapHeight * percentage) / 100,
      left: (mapWidth * percentage) / 100,
      right: (mapWidth * percentage) / 100
    });
  }
}
