import { Injectable, OnDestroy } from '@angular/core';
import { CREATE_EMPTY_CROSS_FILTER_OPTIONS, CrossFilterOptions, ModeWithUniModal } from '@shared/resources/analysis/cross-filter-options';
import { ObjectiveType } from '@shared/resources/analysis/objective-type';
import { HouseholdType } from '@shared/resources/analysis/personfilter/household-type';
import { UrbanDensityType } from '@shared/resources/analysis/personfilter/urban-density-type';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { Subject } from 'rxjs';
import { CrossFilterActionType, CustomEventsConstants } from '../utils/constants/custom-events-constants';
import { AppMonitorService } from './app-monitor.service';

@Injectable()
export class CrossFilteringService implements OnDestroy {
  private filterOptionsChangedSource = new Subject<void>();
  public filterOptionsChanged = this.filterOptionsChangedSource.asObservable();

  private crossFilter = CREATE_EMPTY_CROSS_FILTER_OPTIONS();
  private previousCrossFilter = CREATE_EMPTY_CROSS_FILTER_OPTIONS();

  private ready = false;

  constructor(private appMonitorService: AppMonitorService) {
    this.resetFilter();
    this.ready = true;
  }

  public ngOnDestroy() {
    this.filterOptionsChangedSource.complete();
  }

  public getCrossFilterOptions() {
    return this.crossFilter;
  }

  public resetFilter() {
    this.previousCrossFilter = cloneDeep(this.crossFilter);
    this.crossFilter = CREATE_EMPTY_CROSS_FILTER_OPTIONS();
    this.filterOptionsChangedSource.next();
    this.trace(this.crossFilter, CrossFilterActionType.RESET);
  }

  public setModesFilter(modes: ModeWithUniModal[]) {
    this.previousCrossFilter = cloneDeep(this.crossFilter);
    this.crossFilter.modes = modes;
    this.filterOptionsChangedSource.next();
    this.trace(this.crossFilter, CrossFilterActionType.MODE);
  }

  public setHouseholdsFilter(households: HouseholdType[]) {
    this.previousCrossFilter = cloneDeep(this.crossFilter);
    this.crossFilter.households = households;
    this.filterOptionsChangedSource.next();
    this.trace(this.crossFilter, CrossFilterActionType.HOUSEHOLD);
  }

  public setUrbanDensitiesFilter(urbanDensities: UrbanDensityType[]) {
    this.previousCrossFilter = cloneDeep(this.crossFilter);
    this.crossFilter.urbanDensities = urbanDensities;
    this.filterOptionsChangedSource.next();
    this.trace(this.crossFilter, CrossFilterActionType.URBAN_DENSITY);
  }

  public setObjectivesFilter(objectiveType: ObjectiveType[]) {
    this.previousCrossFilter = cloneDeep(this.crossFilter);
    this.crossFilter.objectives = objectiveType;
    this.filterOptionsChangedSource.next();
    this.trace(this.crossFilter, CrossFilterActionType.OBJECTIVE);
  }

  public restorePreviousCrossFilter() {
    this.crossFilter = cloneDeep(this.previousCrossFilter);
    this.filterOptionsChangedSource.next();
    this.trace(this.crossFilter, CrossFilterActionType.RESTORE_LAST);
  }

  public isCrossFiltering() {
    return !isEqual(this.crossFilter, CREATE_EMPTY_CROSS_FILTER_OPTIONS());
  }

  private trace(crossFilter: CrossFilterOptions, type: CrossFilterActionType) {
    if (this.ready) {
      this.appMonitorService.recordEvent(CustomEventsConstants.APPLY_CROSS_FILTER, { crossFilter, type });
    }
  }
}
