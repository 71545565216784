import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, finalize } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {

  private static spinnerIdCounter = 1;

  private activeSpinners = new Set<number>();

  constructor(router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.activeSpinners.clear();
      }
    });
  }

  get busy() {
    return this.activeSpinners.size > 0;
  }

  /**
   * Returns an RxJs operator that can be piped to a HTTP call. Calling this function shows the
   * spinner while ending/erroring (finalzing) the HTTP call will hide it.
   * If multiple calls are done in parallel, the spinner shows untill all calls are done.
   */
  public register() {
    const spinnerId = SpinnerService.spinnerIdCounter++;
    this.activeSpinners.add(spinnerId);
    return <T>(source: Observable<T>) => {
      return source.pipe(finalize(() => this.activeSpinners.delete(spinnerId)));
    };
  }

}
