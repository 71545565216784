import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum JourneysOrPersons {
  JOURNEYS = 'JOURNEYS',
  PERSONS = 'PERSONS'
}

@Injectable()
export class ToggleJourneysCountsService {
  private toggleJourneysOrPersonsChangedSource = new BehaviorSubject<JourneysOrPersons>(JourneysOrPersons.JOURNEYS);
  public toggleJourneysOrPersonsChanged = this.toggleJourneysOrPersonsChangedSource.asObservable();

  public setToggleJourneysOrPersons(newToggle: JourneysOrPersons) {
    this.toggleJourneysOrPersonsChangedSource.next(newToggle);
  }
}
