<div class="sidebar mat-elevation-z12" [class]="side" [class.collapsed]="collapsed">
  <div class="content">
    <ng-content></ng-content>
  </div>
  <div class="togglebar" [class]="side" (click)="onToggleBarClick()" tabindex="0" (keydown.enter)="onToggleBarClick()"
    (keydown.space)="onToggleBarClick()"
    matTooltip="{{ (collapsed ? 'GLOBAL.EXPAND' : 'GLOBAL.COLLAPSE') | translate }}"
    [matTooltipPosition]="oppositeSide">
    <mat-icon>chevron_{{ side }}</mat-icon>
  </div>
</div>
