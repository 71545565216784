import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LanguageUtils } from "src/app/utils/language-utils";
import { AppDrawerComponent } from './components/app-drawer/app-drawer.component';
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { MainPageLayoutComponent } from './components/main-page-layout/main-page-layout.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { PageSpinnerComponent } from './components/page-spinner/page-spinner.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MaterialModule } from "./material.module";
import { EllipsisPipe } from './pipes/ellipsis.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MainPageLayoutComponent,
    AppDrawerComponent,
    SidebarComponent,
    PageSpinnerComponent,
    MessageDialogComponent,
    EllipsisPipe
  ],
  imports: [

    // Angular
    CommonModule,
    RouterModule,
    FormsModule,

    // Material
    MaterialModule,

    // Other libs
    TranslateModule.forChild(LanguageUtils.getTranslateModuleConfig()),
    NgxSkeletonLoaderModule
  ],
  exports: [
    // Angular
    CommonModule,
    FormsModule,

    // Material
    MaterialModule,

    // Other libs:
    TranslateModule,
    NgxSkeletonLoaderModule,

    // Pipes
    EllipsisPipe,

    // Components
    HeaderComponent,
    MainPageLayoutComponent,
    FooterComponent,
    AppDrawerComponent,
    SidebarComponent,
    PageSpinnerComponent
  ]

})
export class SharedModule {

}
