import { BehaviorSubject, Observable, finalize } from 'rxjs';

export class LocalSpinner {

  private spinnerSubject = new BehaviorSubject<boolean>(false);
  private registrations = 0;

  public readonly state$ = this.spinnerSubject.asObservable();

  get active() {
    return this.registrations > 0;
  }

  public register() {
    this.registrations++;
    this.spinnerSubject.next(true);
    return <T>(source: Observable<T>) => {
      return source.pipe(finalize(() => {
        this.registrations--;
        this.spinnerSubject.next(this.registrations > 0);
      }));
    };
  }

}
