import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CsvDataService {
  public exportToCsv(filename: string, rows: object[]) {
    const separator = ';';
    const endOfLine = '\n';

    const headers = Object.keys(rows[0]);
    const headerRow = headers.join(separator) + endOfLine;
    const cellRows = rows.map((row: any) => {
      return headers.map(header => {
        let cell = row[header] === null || row[header] === undefined ? '' : row[header];
        cell = cell.toString().replace(/"/g, '""');
        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator);
    }).join(endOfLine);
    const csvContent = headerRow + cellRows;
    this.downloadCsv(filename, csvContent);
  }

  private downloadCsv(fileName: string, csvContent: string) {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
