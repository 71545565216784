<app-main-page-layout [pageLabel]="'GLOBAL.NVP_ANALYZER' | translate">

  <div class="container">
    @for (column of columns; track index; let index = $index) {
    <app-compare-column [analysisList]="analysisList"></app-compare-column>
    }
    <button mat-icon-button class="columns-button"
      [matMenuTriggerFor]="columnsMenu"><mat-icon>view_column</mat-icon></button>
  </div>

  <mat-menu #columnsMenu="matMenu">
    @for (numCols of NUMBER_OF_COLUMNS_OPTIONS; track numCols) {
    <button mat-menu-item (click)="setNumberOfColumns(numCols)">
      <mat-icon>{{ numCols === numberOfColumns ? 'radio_button_checked' : 'radio_button_unchecked' }}</mat-icon>
      {{ 'ANALYSIS_COMPARE.SET_COLUMNS' | translate:{numCols} }}
    </button>
    }
  </mat-menu>

  <app-page-spinner message="ANALYSIS_COMPARE.LOADING_ANALYSIS_LIST"></app-page-spinner>

</app-main-page-layout>
