<div class="analysis-selector">
  <mat-form-field>
    <mat-select [(ngModel)]="selectedAnalysis" (ngModelChange)="onSelectedAnalysisChange()"
      placeholder="&mdash; {{ 'GLOBAL.EMPTY' | translate }} &mdash;">
      <mat-option [value]="null">&mdash; {{ 'GLOBAL.EMPTY' | translate }} &mdash;</mat-option>
      @for (analysis of analysisList; track analysis.analysisId) {
      <mat-option [value]="analysis">{{ analysis.title }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <a mat-icon-button [disabled]="!selectedAnalysis" routerLink="/analysis/{{selectedAnalysis?.analysisId}}"
    target="_blank">
    <mat-icon>open_in_new</mat-icon>
  </a>
</div>

<app-analysis-diagram-bar [analysis]="activeAnalysis"></app-analysis-diagram-bar>
