import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Analysis } from '@shared/resources/analysis/analysis';
import { AuthService } from 'src/app/auth/auth.service';
import { ChartService } from 'src/app/services/chart.service';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { MessageService } from 'src/app/services/message.service';
import { JourneysOrPersons, ToggleJourneysCountsService } from 'src/app/services/toggle-journeys-counts.service';
import { LocalStorageConstants } from 'src/app/utils/constants/local-storage-constants';
import { PanelType } from '../nvp-analysis-map/nvp-analysis-map.component';

@Component({
  selector: 'app-analysis-diagram-bar',
  templateUrl: './analysis-diagram-bar.component.html',
  styleUrl: './analysis-diagram-bar.component.scss'
})
export class AnalysisDiagramBarComponent {

  @Input({ required: true }) public analysis: Analysis | null;

  public crossFilterValid = true;
  public chartsOrder = Object.values(PanelType);
  public chartsExpanded = Object.fromEntries(Object.values(PanelType).map(panel => [panel, true])) as { [key in PanelType]: boolean };
  public exportInProgress = false;
  public activeToggle: JourneysOrPersons;

  constructor(
    private crossFilteringService: CrossFilteringService,
    private chartService: ChartService,
    private toggleJourneysCountsService: ToggleJourneysCountsService,
    private authService: AuthService,
    private messageService: MessageService
  ) {
    this.chartsOrder = this.chartsOrder.filter(type => type !== PanelType.TREND); // remove deprecated chart(s)
    this.loadChartsStatus();

    this.chartService.exportOdMatrixInProgress.pipe(takeUntilDestroyed()).subscribe(inProgress => {
      this.exportInProgress = inProgress;
    });

    this.toggleJourneysCountsService.toggleJourneysOrPersonsChanged.pipe(takeUntilDestroyed()).subscribe(activeToggle => {
      this.activeToggle = activeToggle;
    });
  }

  public revertCrossFilter() {
    this.crossFilteringService.restorePreviousCrossFilter();
  }

  public onChartDropped(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.chartsOrder, event.previousIndex, event.currentIndex);
      this.saveChartsStatus();
    }
  }

  public onChartExpandedChange() {
    this.saveChartsStatus();
  }

  public exportChart(panel: string) {
    if (panel in PanelType) {
      this.chartService.triggerExport(panel as PanelType);
    }
  }

  public copyChartToClipboard(panel: string) {
    if (panel in PanelType) {
      this.chartService.triggerCopyToClipboard(panel as PanelType);
    }
  }

  public exportChartToCsv(panel: string) {
    if (this.authService.isTrialUser) {
      this.messageService.showOkButtonDialog('ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_MENU.EXPORT_NOT_ALLOWED');
    } else if (panel in PanelType) {
      this.chartService.triggerExportCsv(panel as PanelType);
    }
  }

  public exportOdMatrix(panel: string) {
    if (this.authService.isTrialUser) {
      this.messageService.showOkButtonDialog('ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_MENU.EXPORT_NOT_ALLOWED');
    } else if (panel in PanelType) {
      this.chartService.triggerExportOdMatrix(panel as PanelType);
    }
  }

  private loadChartsStatus() {
    try {
      const chartsOrder = JSON.parse(localStorage.getItem(LocalStorageConstants.CHARTS_ORDER) as string);
      if (chartsOrder && Array.isArray(chartsOrder)) {
        this.chartsOrder.sort((a, b) => {
          const indexA = chartsOrder.indexOf(a);
          const indexB = chartsOrder.indexOf(b);
          return indexA === -1 || indexB === -1 ? 0 : indexA - indexB;
        });
      }

      const chartsExpanded = JSON.parse(localStorage.getItem(LocalStorageConstants.CHARTS_EXPANDED) as string);
      if (chartsExpanded && typeof chartsExpanded === 'object' && !Array.isArray(chartsExpanded)) {
        Object.values(PanelType).forEach(panel => this.chartsExpanded[panel] = chartsExpanded[panel] !== false);
      }
    } catch (e) {
      console.error(e);
    }
  }

  private saveChartsStatus() {
    localStorage.setItem(LocalStorageConstants.CHARTS_ORDER, JSON.stringify(this.chartsOrder));
    localStorage.setItem(LocalStorageConstants.CHARTS_EXPANDED, JSON.stringify(this.chartsExpanded));
  }
}
