import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MessageDialogButtonType, MessageDialogComponent, MessageDialogData } from '../modules/shared/components/message-dialog/message-dialog.component';

export interface MessageDialogTexts {
  messageLangKey?: string;
  titleLangKey?: string;
  langParams?: any;
  templateRef?: TemplateRef<any>;
}

export enum DialogValue {
  CLOSE = 'CLOSE'
}

@Injectable({ providedIn: 'root' })
export class MessageService {

  public readonly TOASTER_CONFIG: MatSnackBarConfig = {
    duration: 3000,
    panelClass: 'toaster-snackbar',
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  constructor(private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translateService: TranslateService) { }

  public showToaster(messageLangKey: string, langParams?: any) {
    this.snackBar.open(this.translateService.instant(messageLangKey, langParams),
      this.translateService.instant('BUTTON.CLOSE', langParams), this.TOASTER_CONFIG);
  }

  public showOkCancelDialog(text: MessageDialogTexts | string, width?: string, okClicked?: () => void, cancelClicked?: () => void, primaryButton?: MessageDialogButtonType) {
    this.showTwoButtonDialog(['OK', 'CANCEL'], text, width, okClicked, cancelClicked, primaryButton || 'OK');
  }

  public showYesNoDialog(text: MessageDialogTexts | string, width?: string, yesClicked?: () => void, noClicked?: () => void, primaryButton?: MessageDialogButtonType) {
    this.showTwoButtonDialog(['YES', 'NO'], text, width, yesClicked, noClicked, primaryButton || 'YES');
  }

  public showTwoButtonDialog(
    buttonPair: [string, string],
    text: MessageDialogTexts | string,
    width?: string,
    primaryClicked?: () => void,
    secondaryClicked?: () => void,
    primaryButton?: string
  ) {
    let messageDialogText: MessageDialogTexts = text as MessageDialogTexts;
    if (typeof text === 'string') {
      messageDialogText = { messageLangKey: text } as MessageDialogTexts;
    }
    const firstButton = buttonPair[0];
    const secondButton = buttonPair[1];
    this.showMessageDialog(messageDialogText, buttonPair, primaryButton, width, false, (button) => {
      if (button === firstButton && primaryClicked) {
        primaryClicked();
      }
      if (button === secondButton && secondaryClicked) {
        secondaryClicked();
      }
    });
  }

  public showOkButtonDialog(text: MessageDialogTexts | string, width?: string, primaryClicked?: () => void) {
    this.showSingleButtonDialog('OK', text, width, primaryClicked);
  }

  public showSingleButtonDialog(
    button: string,
    text: MessageDialogTexts | string,
    width?: string,
    primaryClicked?: () => void) {
    let messageDialogTexts = text as MessageDialogTexts;
    if (typeof text === 'string') {
      messageDialogTexts = { messageLangKey: text };
    }
    this.showMessageDialog(messageDialogTexts, [button], button, width, false, () => {
      if (primaryClicked) {
        primaryClicked();
      }
    });
  }

  public showMessageDialog(
    text: MessageDialogTexts,
    buttons?: string[],
    primaryButton?: string,
    width?: string,
    allowClosing?: boolean,
    buttonClicked?: (button: string) => void) {

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      // panelClass: 'custom-expert-dialog',
      width: width || 'auto',
      disableClose: !allowClosing,
      data: <MessageDialogData>{
        messageLangKey: text.messageLangKey,
        titleLangKey: text.titleLangKey,
        langParams: text.langParams || {},
        templateRef: text.templateRef,
        buttons: buttons || ['OK'],
        primaryButton: primaryButton || (buttons && buttons[1]) || 'OK'
      }
    });
    dialogRef.afterClosed().subscribe(button => {
      if (buttonClicked) {
        buttonClicked(button ?? DialogValue.CLOSE);
      }
    });
  }

  public showErrorSnackBar(messageLangKey: string, langParams?: any) {
    this.snackBar.open(this.translateService.instant(messageLangKey, langParams),
      this.translateService.instant('BUTTON.CLOSE'), {
      duration: 0,
      panelClass: [messageLangKey.replace(/\./g, "-")] // Adds the language key as css class, so Cypress can target a specific error. Ugly, but effective enough
    });
  }

}
