import { Bounds } from '../../constants/bounds';
import { ofType } from '../../utils/typing-utils';
import { DateTimeFilter } from './datetimefilter/date-time-filter';
import { GeoFilter } from './geofilter/geo-filter';
import { PersonFilter } from './personfilter/personal-filter';
import { TransportFilter } from './transportfilter/transport-filter';

export interface AnalysisFilterOptions {
  geoFilter: GeoFilter;
  personFilter: PersonFilter;
  dateTimeFilter: DateTimeFilter;
  transportFilter: TransportFilter;
}

export const CREATE_EMPTY_ANALYSIS_FILTER_OPTIONS = () => (ofType<AnalysisFilterOptions>({
  personFilter: {
    householdSizeMin: Bounds.MIN_HOUSEHOLDS,
    householdSizeMax: Bounds.MAX_HOUSEHOLDS,
    ageMin: Bounds.MIN_AGE,
    ageMax: Bounds.MAX_AGE,
    genders: [],
    urbanDensity: [],
    possessesCar: [],
    hasDriversLicense: [],
    householdType: [],
    yearIncome: [],
    highestEducation: [],
    occupancy: [],
    workFromHome: [],
    ownedVehicleType: [],
    childrenInSchool: [],
    transportSubscriptionType: []
  },
  geoFilter: {
    entries: [],
  },
  dateTimeFilter: {
    entries: [],
  },
  transportFilter: {
    tripDominantModes: [],
    tripSupportiveModes: [],
    travelTimeMinutesMin: Bounds.MIN_TRAVELTIME_MINUTES,
    travelTimeMinutesMax: Bounds.MAX_TRAVELTIME_MINUTES,
    distanceKmMin: Bounds.MIN_DISTANCE_KM,
    distanceKmMax: Bounds.MAX_DISTANCE_KM,
    avgSpeedKphMin: Bounds.MIN_AVG_SPEED_KPH,
    avgSpeedKphMax: Bounds.MAX_AVG_SPEED_KPH
  },
}));
