import { GeoFilter } from '@shared/resources/analysis/geofilter/geo-filter';
import { CustomRegionGeoFilterEntry, GeoFilterEntry, GeoFilterEntryType, PredefinedGeoFilterEntry, isPredefinedGeoFilterEntry } from '@shared/resources/analysis/geofilter/geo-filter-entry';
import { CustomRegion } from '@shared/resources/geography/custom-region';
import { Municipality } from '@shared/resources/geography/municipality';
import { forkJoin, of, switchMap } from 'rxjs';
import { GeographyHttpService } from 'src/app/services/http/geography-http.service';

export interface GeoFilterOverviewEntry {
  type: GeoFilterEntryType,
  geoType: string,
  names: string[];
}

export class GeoFilterOverviewGenerator {

  constructor(
    private geographyHttpService: GeographyHttpService
  ) { }

  public generate(geoFilter: GeoFilter) {
    const needToRequestMunicipalities = geoFilter.entries.some(entry => isPredefinedGeoFilterEntry(entry));
    const needToRequestRegions = geoFilter.entries.some(entry => entry.geoType === 'CUSTOM_REGION');
    const municipalitiesObs = needToRequestMunicipalities ? this.geographyHttpService.getMunicipalities() : of([] as Municipality[]);
    const regionsObs = needToRequestRegions ? this.geographyHttpService.getCustomRegions() : of([] as CustomRegion[]);

    return forkJoin([municipalitiesObs, regionsObs])
      .pipe(
        switchMap(([allMunicipalities, allRegions]) => {
          return of(this.getGeoFilterEntries(geoFilter, allMunicipalities, allRegions));
        })
      );
  }

  private getGeoFilterEntries(geoFilter: GeoFilter, allMunicipalities: Municipality[], allRegions: CustomRegion[]) {
    return geoFilter.entries.map(entry => {
      if (entry.geoType === 'POLYGON') {
        return this.getPolygonFilterOverviewEntry(entry);
      } else if (isPredefinedGeoFilterEntry(entry)) {
        return this.getPredefinedFilterOverviewEntry(entry, allMunicipalities);
      } else {
        return this.getCustomRegionFilterOverviewEntry(entry, allRegions);
      }
    });
  }

  private getPolygonFilterOverviewEntry(entry: GeoFilterEntry) {
    return this.getGeoFilterOverviewEntry(entry, []);
  }

  private getPredefinedFilterOverviewEntry(entry: PredefinedGeoFilterEntry, allMunicipalities: Municipality[]) {
    const municipalities = allMunicipalities.filter(municipality => entry.codes.includes(municipality.code));
    const municipalitiesNames = municipalities.map(municipality => ' ' + municipality.name);

    return this.getGeoFilterOverviewEntry(entry, municipalitiesNames);
  }

  private getCustomRegionFilterOverviewEntry(entry: CustomRegionGeoFilterEntry, allRegions: CustomRegion[]) {
    const regions = allRegions.filter(region => entry.customRegionIds.includes(region.id));
    const regionNames = regions.map(region => ' ' + region.name);

    return this.getGeoFilterOverviewEntry(entry, regionNames);
  }

  private getGeoFilterOverviewEntry(entry: GeoFilterEntry, names: string[]) {
    return {
      type: entry.type,
      geoType: entry.geoType + '_OVERVIEW',
      names: names
    } as GeoFilterOverviewEntry;
  }

}
