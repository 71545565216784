import * as maplibregl from 'maplibre-gl';

export function get(prop: string | maplibregl.ExpressionSpecification): maplibregl.ExpressionSpecification {
  return ['get', prop];
}

export function toNumber(val: any): number | maplibregl.ExpressionSpecification {
  if (typeof val === 'string' || typeof val === 'number') {
    return Number(val);
  } else {
    return ['to-number', val];
  }
}

export function multiply(...args: (number | maplibregl.ExpressionSpecification)[]): number | maplibregl.ExpressionSpecification {
  if (args.length === 0) {
    throw new TypeError('argument list can not be empty');
  } else if (args.length === 1) {
    return args[0];
  } else if (args.indexOf(0) !== -1) {
    return 0;
  } else {
    const expressions = args.filter(arg => Array.isArray(arg));
    const multiplier = (<number[]>args.filter(arg => !Array.isArray(arg)))
      .reduce((accumulator: number, currentValue: number) => accumulator * currentValue, 1);
    if (expressions.length === 0 || multiplier === 0) {
      return multiplier;
    } else if (multiplier !== 1) {
      return <maplibregl.ExpressionSpecification>['*', ...expressions, ...[multiplier]];
    } else if (expressions.length === 1) {
      return expressions[0];
    } else {
      return ['*', expressions[0], expressions[1], ...(expressions.slice(2))];
    }
  }
}

export function divide(a: number | maplibregl.ExpressionSpecification, b: number | maplibregl.ExpressionSpecification): number | maplibregl.ExpressionSpecification {
  if (!Array.isArray(a) && !Array.isArray(b)) {
    return a / b;
  } else {
    return ['/', a, b];
  }
}

export function interpolateExponential(expBase: number, value: number | maplibregl.ExpressionSpecification, ...stopInOuts: any[])
  : maplibregl.ExpressionSpecification {
  return ['interpolate', ['exponential', expBase], value, ...stopInOuts];
}

export function zoom(): maplibregl.ExpressionSpecification {
  return ['zoom'];
}

export function switchCase(...conditionsAndOutputsAndDefault: any[]): any {
  if (conditionsAndOutputsAndDefault.length === 1) {
    return conditionsAndOutputsAndDefault[0]; // only a default
  } else {
    return ['case', ...conditionsAndOutputsAndDefault];
  }
}

export function greaterThan(val: number | maplibregl.ExpressionSpecification, bound: number | maplibregl.ExpressionSpecification, boundInclusive: boolean = false)
  : boolean | maplibregl.ExpressionSpecification {
  if (!Array.isArray(val) && !Array.isArray(bound)) {
    return boundInclusive ? (val >= bound) : (val > bound);
  } else {
    return [boundInclusive ? '>=' : '>', val, bound];
  }
}

export function lessThan(val: number | maplibregl.ExpressionSpecification, bound: number | maplibregl.ExpressionSpecification, boundInclusive: boolean = false)
  : boolean | maplibregl.ExpressionSpecification {
  if (!Array.isArray(val) && !Array.isArray(bound)) {
    return boundInclusive ? (val <= bound) : (val < bound);
  } else {
    return [boundInclusive ? '<=' : '<', val, bound];
  }
}

export function between(val: number | maplibregl.ExpressionSpecification,
  lowerBound: number | maplibregl.ExpressionSpecification, upperBound: number | maplibregl.ExpressionSpecification,
  lowerBoundInclusive: boolean = false, upperBoundInclusive: boolean = false)
  : boolean | maplibregl.ExpressionSpecification {
  if (!Array.isArray(val) && !Array.isArray(lowerBound) && !Array.isArray(upperBound)) {
    return greaterThan(val, lowerBound, lowerBoundInclusive) && lessThan(val, upperBound, upperBoundInclusive);
  } else {
    return allTrue(greaterThan(val, lowerBound, lowerBoundInclusive), lessThan(val, upperBound, upperBoundInclusive));
  }
}

export function allTrue(...args: (boolean | maplibregl.ExpressionSpecification)[]): boolean | maplibregl.ExpressionSpecification {
  if (args.every(entry => typeof entry === 'boolean')) {
    return args.every(entry => entry);
  } else {
    const nonTrueArgs = args.filter(arg => arg !== true);
    if (nonTrueArgs.length === 0) {
      return true; // everything is true
    } else if (nonTrueArgs.length === 1) {
      return nonTrueArgs[0];
    } else {
      return ['all', ...nonTrueArgs];
    }
  }
}
