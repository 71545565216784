<div class="journey-filters">
  <div class="equal-columns">
    <mat-form-field>
      <mat-label>{{ 'TRANSPORT_FILTER.DOMINANT_MODALITY_LABEL' | translate}}</mat-label>
      <mat-select data-cy="dominant-transport-mode" multiple="true" [(ngModel)]="transportFilter.tripDominantModes">
        @for (transportMode of sortedTransportModes; track transportMode) {
        <mat-option [value]="transportMode">
          {{'TRANSPORT_FILTER.MODES.' + transportMode | translate}}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'TRANSPORT_FILTER.SUPPORTIVE_MODALITY_LABEL' | translate}}</mat-label>
      <mat-select data-cy="supportive-transport-mode" multiple="true" [(ngModel)]="transportFilter.tripSupportiveModes">
        @for (transportMode of sortedTransportModes; track transportMode) {
        <mat-option [value]="transportMode">
          {{'TRANSPORT_FILTER.MODES.' + transportMode | translate}}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="equal-columns">
    <div class="traveltime">
      <div class="header-labels">{{ 'TRANSPORT_FILTER.TRAVELTIME' | translate }}
        ({{ getSliderLabel(transportFilter.travelTimeMinutesMin, transportFilter.travelTimeMinutesMax,
        MIN_TRAVELTIME_MINUTES, MAX_TRAVELTIME_MINUTES) }}{{ 'TRANSPORT_FILTER.MINUTES' | translate }})</div>
      <mat-slider [min]="MIN_TRAVELTIME_MINUTES" [max]="MAX_TRAVELTIME_MINUTES" step="1" discrete
        [displayWith]="formatTravelTimeValue">
        <input matSliderStartThumb [(ngModel)]="transportFilter.travelTimeMinutesMin">
        <input matSliderEndThumb [(ngModel)]="transportFilter.travelTimeMinutesMax">
      </mat-slider>
    </div>
    <div class="avgspeed">
      <div class="header-labels">{{ 'TRANSPORT_FILTER.AVG_SPEED' | translate }}
        ({{ getSliderLabel(transportFilter.avgSpeedKphMin, transportFilter.avgSpeedKphMax,
        MIN_AVG_SPEED_KPH, MAX_AVG_SPEED_KPH) }}{{ 'TRANSPORT_FILTER.KMPH' | translate }})</div>
      <mat-slider [min]="MIN_AVG_SPEED_KPH" [max]="MAX_AVG_SPEED_KPH" step="1" discrete
        [displayWith]="formatAvgSpeedValue">
        <input matSliderStartThumb [(ngModel)]="transportFilter.avgSpeedKphMin">
        <input matSliderEndThumb [(ngModel)]="transportFilter.avgSpeedKphMax">
      </mat-slider>
    </div>
  </div>
  <div class="equal-columns">
    <div class="distance">
      <div class="header-labels">{{ 'TRANSPORT_FILTER.DISTANCE' | translate }}
        ({{ getSliderLabel(transportFilter.distanceKmMin, transportFilter.distanceKmMax,
        MIN_DISTANCE_KM, MAX_DISTANCE_KM) }}{{ 'TRANSPORT_FILTER.KM' | translate }})</div>
      <mat-slider [min]="MIN_DISTANCE_KM" [max]="MAX_DISTANCE_KM" step="1" discrete [displayWith]="formatDistanceValue">
        <input matSliderStartThumb [(ngModel)]="transportFilter.distanceKmMin">
        <input matSliderEndThumb [(ngModel)]="transportFilter.distanceKmMax">
      </mat-slider>
    </div>
  </div>
