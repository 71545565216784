import { Injectable, OnDestroy } from '@angular/core';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import cloneDeep from 'lodash/cloneDeep';
import { Subject, debounceTime, groupBy, map, mergeAll } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageConstants } from '../utils/constants/local-storage-constants';

@Injectable({
  providedIn: 'root'
})
export class AppMonitorService implements OnDestroy {

  private APP_MONITORING_STYLING = { name: "AppMonitoring", color: '#ff9900' };

  private awsRum: AwsRum | null = null;
  private appMonitorDebugMode: boolean;
  private debouncedAppMonitor = new Subject<{ eventType: string, eventData?: object; }>();

  constructor() {
    this.appMonitorDebugMode = localStorage.getItem(LocalStorageConstants.APP_MONITORING_DEBUG_MODE) === 'true';
    if (this.appMonitorDebugMode) {
      console.debug("Debug mode for AppMonitoringService is enabled");
    }

    if (environment.appRumMonitor) {
      try {
        const config: AwsRumConfig = {
          sessionSampleRate: 1,
          guestRoleArn: environment.appRumMonitor?.guestRoleArn,
          identityPoolId: environment.appRumMonitor?.identityPoolId,
          endpoint: environment.appRumMonitor.endpoint,
          telemetries: [
            "errors",
            "performance",
            "http"],
          allowCookies: true,
          disableAutoPageView: true,
          enableXRay: false
        };

        const APPLICATION_ID = environment.appRumMonitor.applicationId;
        const APPLICATION_VERSION = '1.0.0';
        const APPLICATION_REGION = environment.appRumMonitor.region;

        this.awsRum = new AwsRum(
          APPLICATION_ID,
          APPLICATION_VERSION,
          APPLICATION_REGION,
          config
        );
      } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
      }
    }

    this.debouncedAppMonitor.asObservable()
      .pipe(
        groupBy(ev => ev.eventType),
        map(group => group.pipe(debounceTime(5000))),
        mergeAll())
      .subscribe(ev => this.recordEvent(ev.eventType, ev.eventData));
  }

  public ngOnDestroy() {
    this.debouncedAppMonitor.complete();
  }

  public recordPageView(routerUrl: string) {
    this.awsRum?.recordPageView(routerUrl);
    this.trace('Recording pageView', routerUrl);
  }

  public recordError(error: any) {
    this.awsRum?.recordError(error);
    this.trace('Recording error', error);
  }

  public recordEvent(eventType: string, eventData?: object) {
    this.awsRum?.recordEvent(eventType, eventData ?? {});
    this.trace(`Recording event of type: ${eventType}`, eventData);
  }

  public recordEventDebounced5s(eventType: string, eventData?: object) {
    this.debouncedAppMonitor.next({ eventType, eventData });
  }

  public trace(message: string, ...objects: any[]) {
    if (this.appMonitorDebugMode) {
      console.log(` %c${message}\n`, this.APP_MONITORING_STYLING, ...(objects.map(obj => cloneDeep(obj))));
    }
  }
}
