import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GeoFilter } from '@shared/resources/analysis/geofilter/geo-filter';
import { GeoFilterEntry } from '@shared/resources/analysis/geofilter/geo-filter-entry';
import { CustomRegion } from '@shared/resources/geography/custom-region';
import { Municipality } from '@shared/resources/geography/municipality';

@Component({
  selector: 'app-geo-filter',
  templateUrl: './geo-filter.component.html',
  styleUrl: './geo-filter.component.scss',
})
export class GeoFilterComponent {

  @Input({ required: true }) public geoFilter: GeoFilter;
  @Input() public geoFilterDrawingPolygon: GeoFilterEntry | null;
  @Input() public municipalities: Municipality[] = [];
  @Input() public customRegions: CustomRegion[] = [];

  @Output() public filterTypeChanged: EventEmitter<GeoFilterEntry> = new EventEmitter();
  @Output() public geoTypeChanged: EventEmitter<GeoFilterEntry> = new EventEmitter();
  @Output() public deletedEntry: EventEmitter<GeoFilterEntry> = new EventEmitter();

  public onAddGeoFilterClick() {
    const geoFilterEnrty: GeoFilterEntry = { codes: [], geoType: 'MUNICIPALITY', type: 'ORIGIN' };
    this.geoFilter.entries.push(geoFilterEnrty);
  }

  public onFilterTypeChanged(filterEntry: GeoFilterEntry) {
    this.filterTypeChanged.emit(filterEntry);
  }

  public onGeoTypeChanged(filterEntry: GeoFilterEntry) {
    this.geoTypeChanged.emit(filterEntry);
  }

  public onGeoFilterEntryToBeDeleteEvent(geoFilterEntryToBeDeleted: GeoFilterEntry) {
    this.geoFilter.entries = this.geoFilter.entries.filter((entry) => entry !== geoFilterEntryToBeDeleted);
    this.deletedEntry.emit(geoFilterEntryToBeDeleted);
  }
}
