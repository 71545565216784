import { Bounds } from '@shared/constants/bounds';
import { TransportFilter } from '@shared/resources/analysis/transportfilter/transport-filter';

export interface TransportFilterOverviewEntry {
  langKey: string;
  value?: string;
  unitLangKey?: string;
  subLangKeys?: string[];
}

export class TransportFilterOverviewGenerator {

  public generate(transportFilter: TransportFilter) {
    const entries: TransportFilterOverviewEntry[] = [];
    this.addEnumEntry(entries, 'DOMINANT_MODALITY_LABEL', transportFilter.tripDominantModes);
    this.addEnumEntry(entries, 'SUPPORTIVE_MODALITY_LABEL', transportFilter.tripSupportiveModes);
    this.addRangedEntry(entries, 'TRAVELTIME', 'MINUTES',
      transportFilter.travelTimeMinutesMin, transportFilter.travelTimeMinutesMax,
      Bounds.MIN_TRAVELTIME_MINUTES, Bounds.MAX_TRAVELTIME_MINUTES,
    );
    this.addRangedEntry(entries, 'AVG_SPEED', 'KMPH',
      transportFilter.avgSpeedKphMin, transportFilter.avgSpeedKphMax,
      Bounds.MIN_AVG_SPEED_KPH, Bounds.MAX_AVG_SPEED_KPH,
    );
    this.addRangedEntry(entries, 'DISTANCE', 'KM',
      transportFilter.distanceKmMin, transportFilter.distanceKmMax,
      Bounds.MIN_DISTANCE_KM, Bounds.MAX_DISTANCE_KM,
    );
    return entries;
  }

  private addEnumEntry(entries: TransportFilterOverviewEntry[], langKey: string, values: string[]) {
    if (values.length > 0) {
      entries.push({
        langKey: `TRANSPORT_FILTER.${langKey}`,
        subLangKeys: values.map(value => `TRANSPORT_FILTER.MODES.${value}`)
      });
    }
  }

  private addRangedEntry(entries: TransportFilterOverviewEntry[], langKey: string, unitLangKey: 'MINUTES' | 'KMPH' | 'KM', valueMin: number, valueMax: number, boundsMin: number, boundsMax: number) {
    if (valueMin !== boundsMin || valueMax !== boundsMax) {
      let value: string;
      if (valueMax === boundsMax) {
        value = `> ${valueMin}`;
      } else {
        value = `${valueMin} \u2014 ${valueMax}`;
      }
      entries.push({ langKey: `TRANSPORT_FILTER.${langKey}`, value, unitLangKey: `TRANSPORT_FILTER.${unitLangKey}` });
    }
  }
}
