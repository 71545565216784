import { Component, Input } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-page-spinner',
  templateUrl: './page-spinner.component.html',
  styleUrls: ['./page-spinner.component.scss']
})
export class PageSpinnerComponent {

  @Input() message = 'GLOBAL.BUSY';

  constructor(public spinnerService: SpinnerService) { }

}
