import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Bounds } from '@shared/constants/bounds';
import { ValidationConstants } from '@shared/constants/validation-constants';
import { Analysis } from '@shared/resources/analysis/analysis';
import { CREATE_EMPTY_ANALYSIS, CreateAnalysis } from '@shared/resources/analysis/create-analysis';
import {
  CustomRegionGeoFilterEntry, GeoFilterEntry, PolygonGeoFilterEntry, PredefinedGeoFilterEntry
} from '@shared/resources/analysis/geofilter/geo-filter-entry';
import { CustomRegion } from '@shared/resources/geography/custom-region';
import { Municipality } from '@shared/resources/geography/municipality';
import { assert, assertNotNull } from '@shared/utils/assert';
import { DateTimeFilterEntryUtils } from '@shared/utils/date-time-filter-entry-utils';
import { exhaustiveCheck } from '@shared/utils/exhaustive-check';
import { pick } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import defer from 'lodash/defer';
import maplibregl from 'maplibre-gl';
import { forkJoin, of } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AppMonitorService } from 'src/app/services/app-monitor.service';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { GeographyHttpService } from 'src/app/services/http/geography-http.service';
import { MessageService } from 'src/app/services/message.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { CustomEventsConstants } from 'src/app/utils/constants/custom-events-constants';
import { MapStyle } from 'src/app/utils/constants/map-style';
import { MapUtils } from 'src/app/utils/map-utils';

class ValidationError extends Error {
}

@Component({
  selector: 'app-nvp-analysis-input',
  templateUrl: './nvp-analysis-input.component.html',
  styleUrls: ['./nvp-analysis-input.component.scss']
})
export class NvpAnalysisInputComponent implements AfterViewInit, OnDestroy {

  private static readonly INITIAL_POLLING_INTERVAL_MS = 500;
  private static readonly MAX_POLLING_INTERVAL_MS = 5_000;
  private static readonly PERSONS_FROM_ANALYSIS_ID_NOT_YET_SELECTED = -1;

  public mode: 'NEW' | 'EDIT' = 'NEW';
  public analysis: CreateAnalysis = CREATE_EMPTY_ANALYSIS();
  public editingAnalysis: Analysis | null = null;
  public running = false;
  public isTrialUser = false;

  public geoFilterDrawingPolygon: GeoFilterEntry | null = null;
  public polygonFeature: GeoJSON.Feature<GeoJSON.Polygon> | null;
  public municipalities: Municipality[] = [];
  public customRegions: CustomRegion[] = [];
  public availableAnalysesForPanelUsage: Analysis[] = [];

  private map: maplibregl.Map;
  private mapboxDraw: MapboxDraw;
  private currentPollingIntervalMs = NvpAnalysisInputComponent.INITIAL_POLLING_INTERVAL_MS;
  private currentPollingTimerId: number;

  private metadataFormIsValid: boolean = true;

  @ViewChild('map', { static: true }) private mapDivElemRef: ElementRef;
  @ViewChild('expansionPanelGeoFilter', { static: true }) private expansionPanelGeoFilter: MatExpansionPanel;
  @ViewChild('expansionPanelTimeFilter', { static: true }) private expansionPanelTimeFilter: MatExpansionPanel;
  @ViewChild('expansionPanelTransportFilter', { static: true }) private expansionPanelTransportFilter: MatExpansionPanel;
  @ViewChild('expansionPanelPersonFilter', { static: true }) private expansionPanelPersonFilter: MatExpansionPanel;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private analysisHttpService: AnalysisHttpService,
    private geographyHttpService: GeographyHttpService,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private appMonitorService: AppMonitorService,
    authService: AuthService
  ) {
    this.isTrialUser = authService.isTrialUser;

    const editAnalysisId = this.route.snapshot.paramMap.get('id');
    this.mode = editAnalysisId === null ? 'NEW' : 'EDIT';

    this.addDefaultDateTimeEntryForLastYear();

    const duplicateBasedOnAnalysisId = this.route.snapshot.queryParamMap.get('basedOnId');
    assert(duplicateBasedOnAnalysisId === null || this.mode === 'NEW', 'Parameter "basedOnId" on not supported for Edit Analysis');

    const getAnalysis = editAnalysisId !== null
      ? this.analysisHttpService.getAnalysisById(+editAnalysisId)
      : duplicateBasedOnAnalysisId !== null
        ? this.analysisHttpService.getAnalysisById(+duplicateBasedOnAnalysisId)
        : of(null);

    forkJoin([
      this.geographyHttpService.getMunicipalities(),
      this.geographyHttpService.getCustomRegions(),
      this.analysisHttpService.getAnalysisList(),
      getAnalysis
    ])
      .pipe(this.spinnerService.register())
      .subscribe({
        next: ([municipalities, customRegions, analysisList, analysis]) => {
          this.municipalities = municipalities;
          this.customRegions = customRegions;
          this.availableAnalysesForPanelUsage = analysisList.filter(analysisFromList => analysisFromList.state === 'VALID');
          if (analysis) {
            if (this.mode == 'EDIT') {
              this.editingAnalysis = analysis;
              this.availableAnalysesForPanelUsage = this.availableAnalysesForPanelUsage.filter(analysisFromList => analysisFromList.analysisId !== this.editingAnalysis?.analysisId);
              if (analysis.state === 'CREATING') {
                // previous run not done yet, show spinner and poll
                this.checkAnalysisAgainAfterDelay(analysis);
              }
            }
            defer(() => this.fillExistingAnalysis(analysis));
          }
          defer(() => this.expandUsedFilterPanels());
        },
        error: () => this.returnToAnalysisList()
      });
  }

  private returnToAnalysisList() {
    this.router.navigate(['/analysis-list']);
  }

  public ngAfterViewInit() {
    this.map = new maplibregl.Map({
      container: this.mapDivElemRef.nativeElement,
      attributionControl: false,
      style: this.getStyle(),
      center: MapStyle.DEFAULT_MAP_COORDINATES,
      zoom: MapStyle.DEFAULT_MAP_ZOOMLEVEL
    });

    this.mapboxDraw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      }
    });

    this.map.once('styledata', () => this.refreshPolygonLayerSource());
  }

  public ngOnDestroy() {
    this.map?.remove();
    clearTimeout(this.currentPollingTimerId);
  }

  public getAnalysisTitle(): string {
    return this.editingAnalysis?.title ? ` \u2013 ${this.editingAnalysis?.title}` : '';
  }

  public onFilterTypeChanged(geoFilterEntry: GeoFilterEntry) {
    this.refreshPolygonLayerSource();
  }

  public onGeoTypeChanged(geoFilterEntry: GeoFilterEntry) {
    switch (geoFilterEntry.geoType) {
      case 'POLYGON': {
        this.geoFilterDrawingPolygon = geoFilterEntry;
        this.map.addControl(this.mapboxDraw as unknown as maplibregl.IControl);
        this.mapboxDraw.changeMode('draw_polygon');

        this.map.on('draw.create', (event) => {
          this.polygonFeature = (event.features[0]);
        });

        this.map.on('draw.update', (event) => {
          this.polygonFeature = (event.features[0]);
        });
        break;
      }
      default:
        this.refreshPolygonLayerSource();
        if (geoFilterEntry === this.geoFilterDrawingPolygon) {
          this.removeDrawControl();
        }
        break;
    }
  }

  public onGeoFilterDeleted(deletedEntry: GeoFilterEntry) {
    if (deletedEntry.geoType === 'POLYGON') {
      this.refreshPolygonLayerSource();
      this.removeDrawControl();
    }
  }

  public saveGeometry() {
    // prevent a line registering as a polygon
    if (this.polygonFeature && this.polygonFeature?.geometry.coordinates[0].length > 2) {
      (this.geoFilterDrawingPolygon as PolygonGeoFilterEntry).geometry = cloneDeep(this.polygonFeature.geometry);
      this.refreshPolygonLayerSource();
      this.removeDrawControl();
    } else {
      this.messageService.showErrorSnackBar('ANALYSIS_INPUT.ERRORS.NOT_A_VALID_POLYGON');
    }
  }

  public cancelPolygonDrawing() {
    this.analysis.analysisFilterOptions.geoFilter.entries = this.analysis.analysisFilterOptions.geoFilter.entries.filter((entry) => entry !== this.geoFilterDrawingPolygon);
    this.removeDrawControl();
  }

  public saveAnalysis() {
    if (this.validate()) {
      this.running = true;
      this.currentPollingIntervalMs = NvpAnalysisInputComponent.INITIAL_POLLING_INTERVAL_MS;
      this.appMonitorService.recordEvent(CustomEventsConstants.RUN_ANALYSIS, { analysis: this.analysis });
      (this.mode === 'NEW'
        ? this.analysisHttpService.createAnalysis(this.analysis)
        : this.analysisHttpService.updateAnalysis(assertNotNull(this.editingAnalysis).analysisId, this.analysis)
      ).pipe(this.spinnerService.register())
        .subscribe(analysis => {
          this.handleAnalysisResponse(analysis);
        });
    }
  }

  public metadataFormStatusChanged(isValid: boolean) {
    this.metadataFormIsValid = isValid;
  }

  private handleAnalysisResponse(analysis: Analysis) {
    switch (analysis.state) {
      case 'VALID':
        void this.router.navigate(['/analysis/', analysis.analysisId]);
        break;
      case 'INVALID':
        this.messageService.showErrorSnackBar('ANALYSIS_INPUT.ERRORS.INVALID_PERSON_COUNT');
        this.appMonitorService.recordEvent(CustomEventsConstants.ANALYSIS_RESULT_FAILED_PRIVACY, {
          analysisId: analysis.analysisId,
          analysis: this.analysis
        });
        this.running = false;
        break;
      case 'ERROR':
        this.messageService.showErrorSnackBar('ANALYSIS_INPUT.ERRORS.UNKNOWN_ERROR');
        this.running = false;
        break;
      case 'CREATING':
        this.checkAnalysisAgainAfterDelay(analysis);
        break;
      default:
        throw exhaustiveCheck(analysis.state);
    }
  }

  private checkAnalysisAgainAfterDelay(prevAnalysis: Analysis) {
    this.running = true;
    const spinnerOperator = this.spinnerService.register(); // Show spinner already before polling interval
    this.currentPollingTimerId = window.setTimeout(() => {
      this.analysisHttpService.getAnalysisById(prevAnalysis.analysisId)
        .pipe(spinnerOperator)
        .subscribe(analysis => this.handleAnalysisResponse(analysis));
    }, this.currentPollingIntervalMs);
    this.currentPollingIntervalMs = Math.min(this.currentPollingIntervalMs * 1.5, NvpAnalysisInputComponent.MAX_POLLING_INTERVAL_MS);
  }

  private fillExistingAnalysis(analysis: Analysis) {
    this.analysis = cloneDeep(pick(analysis, ['title', 'projectCode', 'description', 'analysisFilterOptions']));
    this.analysis.analysisFilterOptions.dateTimeFilter.entries.forEach(entry => {
      // Date objects got serialized as strings -- convert them back
      entry.startDate = new Date(String(entry.startDate));
      entry.endDate = new Date(String(entry.endDate));
    });
    if (this.mode == 'NEW') {
      // When duplicating, metadata is not duplicated
      this.analysis.title = '';
      this.analysis.projectCode = '';
      this.analysis.description = '';
    }

    this.validateAnalysisForPanelIsStillValid();
    this.refreshPolygonLayerSource();
    this.fitBounds();
  }

  private validateAnalysisForPanelIsStillValid() {
    const selectedPersonsFromAnalysisId = this.analysis.analysisFilterOptions.personFilter.personsFromAnalysisId;
    const selectedAnalysisExistsAndIsValid = !!this.availableAnalysesForPanelUsage.find(a => a.analysisId === selectedPersonsFromAnalysisId);
    if (selectedPersonsFromAnalysisId && !selectedAnalysisExistsAndIsValid) {
      this.messageService.showOkButtonDialog({
        titleLangKey: 'PERSON_FILTER.PANEL_ANALYSIS_NO_LONGER_AVAILABLE_TITLE',
        messageLangKey: 'PERSON_FILTER.PANEL_ANALYSIS_NO_LONGER_AVAILABLE_MESSAGE'
      });
      // Keep the dropdown visible, but throw an error if no action is performed
      this.analysis.analysisFilterOptions.personFilter.personsFromAnalysisId = NvpAnalysisInputComponent.PERSONS_FROM_ANALYSIS_ID_NOT_YET_SELECTED;
    }
  }

  private refreshPolygonLayerSource() {
    const polygonGeoFilters = this.analysis.analysisFilterOptions.geoFilter.entries.filter(entry => entry.geoType === 'POLYGON')
      .map(entry => (entry as PolygonGeoFilterEntry));

    (this.map.getSource('polygon-source') as maplibregl.GeoJSONSource)?.setData({
      type: 'FeatureCollection',
      features: polygonGeoFilters.map((filter, index) => ({
        type: 'Feature',
        properties: { id: index, filterType: filter.type },
        geometry: filter.geometry
      }))
    });
  }

  private fitBounds() {
    setTimeout(() => MapUtils.fitBoundsForSource(this.map, 'polygon-source'), 1000);
  }

  private removeDrawControl() {
    const drawControl = this.mapboxDraw as unknown as maplibregl.IControl;
    if (this.map.hasControl(drawControl)) {
      this.map.removeControl(drawControl);
    }
    this.polygonFeature = null;
    this.geoFilterDrawingPolygon = null;
  }

  private getStyle(): maplibregl.StyleSpecification {
    return {
      ...MapStyle.EMPTY_STYLE,
      sources: {
        'background-source': MapStyle.DEFAULT_BACKGROUND_SOURCE,
        'polygon-source': {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: [] }
        }
      },
      layers: [MapStyle.DEFAULT_BACKGROUND_LAYER,
      {
        'id': 'polygon',
        'type': 'fill',
        'source': 'polygon-source',
        'layout': {
          visibility: 'visible'
        },
        'paint': {
          'fill-color': [
            'match',
            ['get', 'filterType'],
            'ORIGIN', '#ff9736',
            'DESTINATION', '#93C54B',
            'PASS_THROUGH', '#5A427E',
            'INTERSECT', '#0E518D',
            '#000000' // Default color if no match is found
          ],
          'fill-opacity': 0.5
        }

      },
      {
        'id': 'polygon-outline',
        'type': 'line',
        'source': 'polygon-source',
        'layout': {
          visibility: 'visible'
        },
        'paint': {
          'line-color': [
            'match',
            ['get', 'filterType'],
            'ORIGIN', '#ff9736',
            'DESTINATION', '#93C54B',
            'PASS_THROUGH', '#5A427E',
            'INTERSECT', '#0E518D',
            '#000000' // Default color if no match is found
          ],
          'line-width': 1,
          'line-opacity': 0.5
        }
      }
      ]
    };
  }

  private validate() {
    try {
      this.validateMetadata();
      this.validateMinimalFilters();
      this.validateGeoFilters();
      this.validateDateTimeFilters();
      this.validatePersonsFromAnalysisIdExists();
      return true;
    } catch (e) {
      if (e instanceof ValidationError) {
        this.messageService.showOkButtonDialog({ messageLangKey: e.message, titleLangKey: 'ANALYSIS_INPUT.INCORRECT' });
      }
      return false;
    }
  }

  private validateMetadata() {
    this.validateCondition(this.metadataFormIsValid, 'ANALYSIS_INPUT.ERRORS.INVALID_METADATA_FORM');
  }

  private validateMinimalFilters() {
    const hasGeoFilters = this.analysis.analysisFilterOptions.geoFilter.entries.length > 0;
    const hasPersonsFromOtherAnalysis = this.analysis.analysisFilterOptions.personFilter.personsFromAnalysisId !== undefined;
    this.validateCondition(hasGeoFilters || hasPersonsFromOtherAnalysis, 'ANALYSIS_INPUT.ERRORS.MINIMAL_FILTERS_NEEDED');
  }

  private validateGeoFilters() {
    this.validateCondition(this.analysis.analysisFilterOptions.geoFilter.entries
      .filter((entry): entry is PredefinedGeoFilterEntry => (entry.geoType !== 'POLYGON' && entry.geoType !== 'CUSTOM_REGION'))
      .every(entry => entry.codes.length > 0),
      'ANALYSIS_INPUT.ERRORS.GEO_CODES_EMPTY');
    this.validateCondition(this.analysis.analysisFilterOptions.geoFilter.entries
      .filter((entry): entry is CustomRegionGeoFilterEntry => entry.geoType === 'CUSTOM_REGION')
      .every(entry => entry.customRegionIds.length > 0),
      'ANALYSIS_INPUT.ERRORS.GEO_CODES_EMPTY');
  }

  private validateDateTimeFilters() {
    this.validateCondition(this.analysis.analysisFilterOptions.dateTimeFilter.entries
      .every(entry => entry.startDate && entry.endDate),
      'ANALYSIS_INPUT.ERRORS.START_END_DATE_MISSING');
    this.validateCondition(this.analysis.analysisFilterOptions.dateTimeFilter.entries
      .every(entry => entry.startDate && entry.endDate && entry.startDate <= entry.endDate
        && entry.startDate >= ValidationConstants.FIRST_JOURNEY_DATE && entry.endDate <= new Date()),
      'ANALYSIS_INPUT.ERRORS.START_END_DATE_WRONG');

    if (this.isTrialUser) {
      this.validateCondition(this.analysis.analysisFilterOptions.dateTimeFilter.entries.length > 0,
        'ANALYSIS_INPUT.ERRORS.TRIAL_REQUIRES_DATE_TIME_FILTER');
      const minTrialDate = DateTimeFilterEntryUtils.getMostRecentYearRange().startDate;
      this.validateCondition(this.analysis.analysisFilterOptions.dateTimeFilter.entries
        .every(entry => entry.startDate >= minTrialDate),
        'ANALYSIS_INPUT.ERRORS.TRIAL_MINIMUM_DATE');
    }
  }

  private validatePersonsFromAnalysisIdExists() {
    const personsFromAnalysisId = this.analysis.analysisFilterOptions.personFilter.personsFromAnalysisId;
    this.validateCondition(personsFromAnalysisId === undefined || this.availableAnalysesForPanelUsage.find(analysis => analysis.analysisId === personsFromAnalysisId), 'ANALYSIS_INPUT.ERRORS.INVALID_PANEL_ANALYSIS_SELECTED');
  }

  private validateCondition(condition: any, messageLangKey: string): asserts condition {
    if (!condition) throw new ValidationError(messageLangKey);
  }

  private expandUsedFilterPanels() {
    this.expansionPanelGeoFilter.expanded = true;
    this.expansionPanelTimeFilter.expanded = this.analysis.analysisFilterOptions.dateTimeFilter.entries.length > 0 || this.isTrialUser;
    this.expansionPanelTransportFilter.expanded = this.isUsingTransportFilter();
    this.expansionPanelPersonFilter.expanded = this.isUsingPersonFilter();
  }

  private isUsingTransportFilter() {
    const transportFilter = this.analysis.analysisFilterOptions.transportFilter;
    return transportFilter.tripDominantModes.length > 0
      || transportFilter.tripSupportiveModes.length > 0
      || transportFilter.travelTimeMinutesMin !== Bounds.MIN_TRAVELTIME_MINUTES
      || transportFilter.travelTimeMinutesMax !== Bounds.MAX_TRAVELTIME_MINUTES
      || transportFilter.avgSpeedKphMin !== Bounds.MIN_AVG_SPEED_KPH
      || transportFilter.avgSpeedKphMax !== Bounds.MAX_AVG_SPEED_KPH
      || transportFilter.distanceKmMin !== Bounds.MIN_DISTANCE_KM
      || transportFilter.distanceKmMax !== Bounds.MAX_DISTANCE_KM;
  }

  private isUsingPersonFilter() {
    const personFilter = this.analysis.analysisFilterOptions.personFilter;
    return !!personFilter.personsFromAnalysisId
      || personFilter.genders.length > 0
      || personFilter.possessesCar.length > 0
      || personFilter.hasDriversLicense.length > 0
      || personFilter.urbanDensity.length > 0
      || personFilter.yearIncome.length > 0
      || personFilter.householdType.length > 0
      || personFilter.highestEducation.length > 0
      || personFilter.occupancy.length > 0
      || personFilter.ageMin !== Bounds.MIN_AGE
      || personFilter.ageMax !== Bounds.MAX_AGE
      || personFilter.householdSizeMin !== Bounds.MIN_HOUSEHOLDS
      || personFilter.householdSizeMax !== Bounds.MAX_HOUSEHOLDS;
  }

  private addDefaultDateTimeEntryForLastYear() {
    this.analysis.analysisFilterOptions.dateTimeFilter.entries.push({
      ...DateTimeFilterEntryUtils.getMostRecentYearRange(),
      startTime: 0,
      endTime: 23,
      daysOfWeek: [1, 2, 3, 4, 5, 6, 7]
    });
  }
}
