<div class="host">
  <app-header [pageLabel]="pageLabel">
  </app-header>
  <div class="page-content" [class.styled-background]="styledBackground">
    @if (styledBackground) {
    <img class="background-lines top-left" alt="background-lines" src="/assets/background-lines-grey.svg" />
    <img class="background-lines bottom-right" alt="background-lines" src="/assets/background-lines-grey.svg" />
    }
    <div class="page-wrap">
      <ng-content></ng-content>
    </div>
  </div>
  @if (footer) {
  <app-footer></app-footer>
  }
</div>
