import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, mergeMap, throwError } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Constants } from '../../../utils/constants/constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!request.url.startsWith(Constants.API_PREFIX)) {
      return next.handle(request);
    }

    return this.authService.isAuthenticatedAndAuthorized().pipe(
      mergeMap((authenticated) => {
        if (authenticated !== 'OK') {
          return throwError(() => new Error('User is not authenticated or authorized'));
        }
        return this.authService.getAccessToken();
      }),
      mergeMap((accessToken) => {
        const cloned = request.clone({
          headers: request.headers.set(
            'Authorization',
            'Bearer ' + accessToken
          ),
        });
        return next.handle(cloned);
      })
    );
  }
}
