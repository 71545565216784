<h2 mat-dialog-title *ngIf="data.titleLangKey">{{ data.titleLangKey | translate:data.langParams }}</h2>
<div mat-dialog-content class="mat-typography">
  <p *ngIf="data.messageLangKey">{{ data.messageLangKey | translate:data.langParams }}</p>
  <p *ngIf="data.templateRef">
    <ng-container *ngTemplateOutlet="data.templateRef"></ng-container>
  </p>
</div>
<div mat-dialog-actions class="buttons">
  <ng-container *ngFor="let button of data.buttons">
    <button *ngIf="button === data.primaryButton" mat-button [mat-dialog-close]="button" [color]="'primary'"
      cdkFocusInitial data-cy="primaryButton">
      {{'BUTTON.' + button | translate}}
    </button>
    <button *ngIf="button !== data.primaryButton" mat-button [mat-dialog-close]="button" [color]="''">
      {{'BUTTON.' + button | translate}}
    </button>
  </ng-container>
</div>
