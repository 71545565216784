import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DisclaimerDialogComponent } from 'src/app/components/disclaimer-dialog/disclaimer-dialog.component';
import { LanguageUtils } from 'src/app/utils/language-utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  private static readonly DEFAULT_USER_IMAGE = '/assets/default-user-img.jpg';
  public readonly SUPPORT_URL = 'mailto:support@dat.nl?subject=NVP Analyzer Feedback';

  @Input() public pageLabel: string;

  public readonly languages: string[] = LanguageUtils.SUPPORTED_LANGUAGES;
  public currentLanguage: string;
  public userData?: { name: string, email: string, picture: string; };

  constructor(
    private translateService: TranslateService,
    private oidcSecurityService: OidcSecurityService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.currentLanguage = translateService.currentLang;
    this.oidcSecurityService.userData$.pipe(
      map(userData => userData.userData)
    ).subscribe(userdata => {
      this.userData = userdata;
    });
  }

  public onLanguageClick(language: string) {
    this.currentLanguage = language;
    LanguageUtils.changeLanguage(this.translateService, language);
  }

  public onLogoutClick() {
    this.authService.logoff();
  }

  public onShowNvpDisclaimerClick() {
    DisclaimerDialogComponent.showDisclaimer(this.dialog, { showIfAccepted: true });
  }

  public getUserMediaImageUrl() {
    return this.userData?.picture || HeaderComponent.DEFAULT_USER_IMAGE;
  }
}
