import { TransportModeType } from '@shared/resources/analysis/transport-mode-type';
import { environment } from 'src/environments/environment';

export class Constants {

  public static readonly API_VERSION = 1;
  public static readonly API_PREFIX = `${environment.apiUri}/api/v${Constants.API_VERSION}`;

  public static readonly COLOR_CHART_CATEGORIES = ['#3fcfd5', '#cb0044', '#ffde23', '#37424a', '#00aa4d', '#ff6e23', '#8737a5', '#2058a2', '#653428'];
  public static readonly COLOR_CHART_CATEGORIES_XL = [
    '#00a7e7', '#ffe500', '#c8d300', '#ea5297',
    '#0080c9', '#fdc300', '#53b151', '#9f4c88',
    '#023a7d', '#f18a00', '#00a295', '#5a427e',
    '#394053', '#e63312', '#007174', '#4f4757',
  ];
  public static readonly COLOR_DAT_BLUE = '#3fcfd5';
  public static readonly COLOR_DAT_LIGHTGRAY = '#e8e7e8';
  public static readonly COLOR_DAT_MIDGRAY = '#6a757c';
  public static readonly COLOR_DAT_DARKGRAY = '#4b5159';
  public static readonly COLOR_DAT_EXTRADARKGRAY = '#232931';

  public static readonly COLORS_PER_MODE: { [key in TransportModeType]: string } = {
    AIRPLANE: '#007676',
    BIKE: '#53B151',
    BOAT: '#584883',
    BUS: '#0080C9',
    CAR: '#394052',
    EBIKE: '#494555', // missing
    ECAR: '#494555', // missing
    FERRY: '#504080',
    FOOT: '#EA5297',
    LIGHTRAIL: '#804900',
    METRO: '#9FC88',
    MOPED: '#494555', // missing
    MOTOR: '#405280',
    OTHER: '#494555',
    PUBLIC_TRANSPORT: '#494555', // missing
    TAXI: '#494555',  // missing
    TRAIN: '#FDC300',
    TRAM: '#F18A00',
    UNKNOWN: '#63718'
  };

}
