export class Bounds {

  public static readonly MIN_AGE = 16;
  public static readonly MAX_AGE = 100;

  public static readonly MIN_HOUSEHOLDS = 1;
  public static readonly MAX_HOUSEHOLDS = 11;

  public static readonly MIN_TRAVELTIME_MINUTES = 0;
  public static readonly MAX_TRAVELTIME_MINUTES = 240;

  public static readonly MIN_DISTANCE_KM = 0;
  public static readonly MAX_DISTANCE_KM = 400;

  public static readonly MIN_AVG_SPEED_KPH = 0;
  public static readonly MAX_AVG_SPEED_KPH = 200;

}
