import { ofType } from '../../utils/typing-utils';
import { ObjectiveType } from './objective-type';
import { HouseholdType } from './personfilter/household-type';
import { UrbanDensityType } from './personfilter/urban-density-type';
import { TransportModeType } from './transport-mode-type';

export interface CrossFilterOptions {
  modes: ModeWithUniModal[];
  households: HouseholdType[];
  urbanDensities: UrbanDensityType[];
  objectives: ObjectiveType[];
}

export interface ModeWithUniModal {
  mode: TransportModeType;
  uniModal: boolean;
}

export const CREATE_EMPTY_CROSS_FILTER_OPTIONS = () => (ofType<CrossFilterOptions>({
  modes: [],
  households: [],
  urbanDensities: [],
  objectives: []
}));
