import { Observable, delay, of, switchMap, take } from 'rxjs';

/**
 * rxjs operator that delays until another observable emits
 */
export function waitFor<T>(signal: Observable<any>) {
  return (source: Observable<T>) => signal.pipe(
    take(1),
    switchMap(_ => source),
  );
}

/**
 * returns an observable that delays calling of the input observable with the given amount of milliseconds.
 * can be used to delay http calls for polling purposes.
 * note that simply using the delay() operator on the http call directly, would delay the output, not the call.
 */
export function delayObservable<T>(observable: Observable<T>, durationMs: number) {
  return of(null).pipe(
    delay(durationMs),
    switchMap(() => observable)
  );
}
