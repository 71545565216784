export type GeoFilterEntryType = 'ORIGIN' | 'DESTINATION' | 'PASS_THROUGH' | 'INTERSECT';
export const PREDEFINED_GEO_TYPES = ['MUNICIPALITY', 'DISTRICT', 'NEIGHBORHOOD', 'PC4'] as const;
export type PredefinedGeoType = typeof PREDEFINED_GEO_TYPES[number];

export interface PolygonGeoFilterEntry {
  type: GeoFilterEntryType;
  geoType: 'POLYGON';
  geometry: GeoJSON.Polygon;
}

export interface PredefinedGeoFilterEntry {
  type: GeoFilterEntryType;
  geoType: PredefinedGeoType;
  codes: string[];
}

export interface CustomRegionGeoFilterEntry {
  type: GeoFilterEntryType;
  geoType: 'CUSTOM_REGION';
  customRegionIds: number[];
}

export type GeoFilterEntry = PolygonGeoFilterEntry | PredefinedGeoFilterEntry | CustomRegionGeoFilterEntry;

export function isPredefinedGeoFilterEntry(entry: GeoFilterEntry): entry is PredefinedGeoFilterEntry {
  return (PREDEFINED_GEO_TYPES as readonly string[]).includes(entry.geoType);
}
