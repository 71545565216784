import { Component, Input } from '@angular/core';
import { Bounds } from '@shared/constants/bounds';
import { Analysis } from '@shared/resources/analysis/analysis';
import { ChildrenInSchoolType } from '@shared/resources/analysis/personfilter/children-in-school-type';
import { HighestEducationType } from '@shared/resources/analysis/personfilter/highest-education-type';
import { HouseholdType } from '@shared/resources/analysis/personfilter/household-type';
import { OccupancyType } from '@shared/resources/analysis/personfilter/occupancy-type';
import { PersonFilter } from '@shared/resources/analysis/personfilter/personal-filter';
import { PossessesTransportType } from '@shared/resources/analysis/personfilter/possesses-transport-type';
import { SubscribedToTransportType } from '@shared/resources/analysis/personfilter/subscribed-to-transport-types';
import { UrbanDensityType } from '@shared/resources/analysis/personfilter/urban-density-type';
import { WorkFromHomeType } from '@shared/resources/analysis/personfilter/work-from-home-type';
import { YearIncomeType } from '@shared/resources/analysis/personfilter/year-income-type';

@Component({
  selector: 'app-person-filter',
  templateUrl: './person-filter.component.html',
  styleUrls: ['./person-filter.component.scss'],
})
export class PersonFilterComponent {

  public readonly URBAN_DENSITIES: UrbanDensityType[] = ['HIGH_DENSITY', 'LOW_DENSITY', 'MAX_DENSITY', 'MEDIUM_DENSITY', 'MINIMAL_DENSITY'];
  public readonly HOUSEHOLD_TYPES: HouseholdType[] = [
    'ADULT_HOUSEHOLD',
    'SINGLE',
    'UNKNOWN',
    'WITH_ADOLESCENT_CHILDREN',
    'WITH_OLDER_CHILDREN',
    'WITH_YOUNG_CHILDREN',
  ];
  public readonly YEAR_INCOME_TYPES: YearIncomeType[] = [
    'HIGHER_THAN_TWO_TIMES_MODAL',
    'LOWER_THAN_MODAL',
    'MINIMUM',
    'MODAL',
    'ONE_TO_TWO_TIMES_MODAL',
    'TWO_TIMES_MODAL',
    'UNKNOWN',
  ];
  public readonly HIGHEST_EDUCATION_TYPES: HighestEducationType[] = [
    'BACHELOR_EDUCATION',
    'HIGHER_SECONDARY_EDUCATION',
    'LOWER_SECONDARY_EDUCATION',
    'MASTER_OR_DOCTORAL',
    'MEDIUM_SECONDARY_EDUCATION',
    'MEDIUM_TERTIARY_EDUCATION',
    'PRIMARY_SCHOOL_ONLY',
    'UNKNOWN',
  ];
  public readonly OCCUPANCY_TYPES: OccupancyType[] = [
    'AT_HOME',
    'EMPLOYED',
    'ENTREPRENEUR',
    'GOVERNMENTAL',
    'INCAPACITATED',
    'RETIRED',
    'STUDYING',
    'UNEMPLOYED',
    'UNKNOWN',
  ];
  public readonly WORK_FROM_HOME_TYPES: WorkFromHomeType[] = [
    'NEVER',
    'LESS_THAN_ONE_DAY_PER_MONTH',
    'ONE_TO_THREE_DAYS_PER_MONTH',
    'ONE_DAY_PER_WEEK',
    'TWO_TO_THREE_DAYS_PER_WEEK',
    'FOUR_TO_FIVE_DAYS_PER_WEEK',
    'SIX_OR_MORE_DAYS_PER_WEEK',
    'UNKNOWN',
  ];
  public readonly POSSESSES_TRANSPORT_TYPES: PossessesTransportType[] = [
    'BICYCLE',
    'ELECTRIC_BICYCLE',
    'MOBILITY_SCOOTER',
    'MOPED_SCOOTER',
    'MOTORCYCLE',
    'PRIVATE_CAR',
    'LEASE_CAR',
    'PRIVATE_ELECTRIC_CAR',
    'LEASE_ELECTRIC_CAR',
    'TARGET_GROUP_TRANSPORT',
    'OTHER',
    'UNKNOWN',
  ];
  public readonly SUBSCRIBED_TO_TRANSPORT_TYPES: SubscribedToTransportType[] = [
    'SHARED_BICYCLE',
    'ELECTRIC_SHARED_BICYCLE',
    'PUBLIC_BICYCLE',
    'SHARED_SCOOTER',
    'ELECTRIC_SHARED_SCOOTER',
    'SHARED_CAR',
    'ELECTRIC_SHARED_CAR',
    'BUS',
    'TRAIN',
    'OTHER',
    'UNKNOWN'
  ];
  public readonly CHILDREN_IN_SCHOOL_TYPES: ChildrenInSchoolType[] = [
    'YES',
    'NO',
    'UNKNOWN'
  ];

  public readonly MIN_AGE = Bounds.MIN_AGE;
  public readonly MAX_AGE = Bounds.MAX_AGE;
  public readonly MIN_HOUSEHOLDS = Bounds.MIN_HOUSEHOLDS;
  public readonly MAX_HOUSEHOLDS = Bounds.MAX_HOUSEHOLDS;

  public readonly PERSONS_FROM_ANALYSIS_ID_NOT_YET_SELECTED = -1;

  @Input({ required: true }) public personFilter: PersonFilter;
  @Input({ required: true }) public analysisList: Analysis[];

  public toggleUseOtherAnalysis() {
    this.personFilter.personsFromAnalysisId = this.personFilter.personsFromAnalysisId ? undefined : this.PERSONS_FROM_ANALYSIS_ID_NOT_YET_SELECTED;
  }
}
