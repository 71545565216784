import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  @Input({ required: true }) public side: 'left' | 'right';
  @Input({ required: true }) public collapsed: boolean;
  @Input({ required: true }) @HostBinding('style.--sidebar-width') public width: string;

  @Output() public collapsedChange = new EventEmitter<boolean>();

  get oppositeSide() {
    return this.side === 'left' ? 'right' : 'left';
  }

  public onToggleBarClick() {
    this.collapsed = !this.collapsed;
    this.collapsedChange.emit(this.collapsed);
  }

}
