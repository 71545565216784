export function isNonNull<T>(value: T): value is NonNullable<T> {
  return value !== null;
}

/**
 * Ensures the incoming object matches the given type T and does not contain any excess
 * properties. Returns the incoming object with the correct type.
 *
 * This is useful when creating anonymous objects that should match a type/interface/class.
 * Because in this case, excess properties are not always caught, see:
 * https://dev.to/this-is-learning/understanding-excess-property-checking-in-typescript-ook
 */
export function ofType<T>(value: { [K in keyof T]: T[K] }): T {
  return value as T;
}

