export class MapStyle {

  public static readonly EMPTY_STYLE: maplibregl.StyleSpecification = {
    version: 8,
    glyphs: '/assets/maplibre/glyphs/{fontstack}/{range}.pbf',
    sources: {},
    layers: [],
  };

  public static readonly DEFAULT_BACKGROUND_SOURCE: maplibregl.RasterSourceSpecification = {
    type: 'raster',
    tileSize: 256,
    maxzoom: 19,
    bounds: [-1.657292, 48.040502, 12.431727, 56.11059],
    tiles: ['https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/standaard/EPSG:3857/{z}/{x}/{y}.png'],
  };

  public static readonly DEFAULT_BACKGROUND_LAYER: maplibregl.RasterLayerSpecification = {
    id: 'background',
    type: 'raster',
    source: 'background-source',
    paint: { 'raster-saturation': -1 },
  };

  public static readonly DEFAULT_MAP_COORDINATES: [longitutde: number, latitude: number] = [5.458888210663844, 51.94794980281775];
  public static readonly DEFAULT_MAP_ZOOMLEVEL = 8;

  public static readonly ORIGIN_POLYGON_COLORS = ['#feedde', '#fdd0a2', '#fdae6b', '#fd8d3c', '#f16913', '#d94801', '#8c2d04']; // https://colorbrewer2.org/#type=sequential&scheme=Oranges&n=7
  public static readonly DESTINATION_POLYGON_COLORS = ['#edf8e9', '#c7e9c0', '#a1d99b', '#74c476', '#41ab5d', '#238b45', '#005a32']; // https://colorbrewer2.org/?type=sequential&scheme=Greens&n=7

}
