import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateDefaultParser, TranslateLoader, TranslateModuleConfig, TranslateParser, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { enUS, nl } from 'date-fns/locale';
import { LocalStorageConstants } from './constants/local-storage-constants';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function dateFnsLocaleFactory(translateService: TranslateService) {
  switch (translateService.currentLang) {
    case 'en': return enUS;
    default: return nl;
  }
}

/**
 * Extension to the default parser for ngx-translate, which supports the syntax
 *
 * {{numAlternatives 1? alternatief : alternatieven}}
 *
 * to make a distinction between singular and plural phrases. The attribute before 1? must be supplied in the params
 * object, just like normal variable interpolation. After the 1? you can enter the phrases for singular and plural.
 */
@Injectable()
export class TranslatePluralParser extends TranslateDefaultParser {
  private templatePluralMatcher: RegExp = /{{\s*([^{}\s]+)\s*1\?\s?([^{}:]+?)\s?:\s?([^{}]+?)\s?}}/g;

  // eslint-disable-next-line @typescript-eslint/ban-types
  public override interpolate(expr: string | Function, params?: any): string {
    if (params) {
      return this.pluralize(super.interpolate(expr, params), params);
    } else {
      return super.interpolate(expr, params);
    }
  }

  private pluralize(expr: string, params: any) {
    return expr.replace(this.templatePluralMatcher, (substring: string, prop: string, singular: string, plural: string) => {
      const r = this.getValue(params, prop);
      return (typeof r !== 'number') ? substring : (+r === 1 ? singular : plural);
    });
  }
}

export class LanguageUtils {

  public static readonly SUPPORTED_LANGUAGES = ['nl', 'en'];
  public static readonly DEFAULT_LANGUAGE = 'nl';

  public static getTranslateModuleConfig(): TranslateModuleConfig {
    return {
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      parser: { provide: TranslateParser, useClass: TranslatePluralParser },
    };
  }

  public static initLanguage(translateService: TranslateService) {
    const localStorageLang = localStorage.getItem(LocalStorageConstants.LANGUAGE);
    if (localStorageLang && LanguageUtils.SUPPORTED_LANGUAGES.indexOf(localStorageLang) !== -1) {
      translateService.use(localStorageLang);
    } else {
      translateService.use(LanguageUtils.DEFAULT_LANGUAGE);
    }
  }

  public static changeLanguage(translateService: TranslateService, language: string) {
    localStorage.setItem(LocalStorageConstants.LANGUAGE, language);
    translateService.use(language);
  }

}

export class DynamicLocaleId extends String {
  constructor(protected translateService: TranslateService) {
    super('');
  }

  public override toString() {
    return this.translateService.currentLang || LanguageUtils.DEFAULT_LANGUAGE;
  }
}
