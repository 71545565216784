import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { Analysis } from '@shared/resources/analysis/analysis';
import { AnalysisState } from '@shared/resources/analysis/analysis-state';
import { OriginOrDestination } from '@shared/resources/analysis/origin-or-destination';
import { PolygonLevel } from '@shared/resources/geography/polygon-level';
import { assert, assertNotNull } from '@shared/utils/assert';
import { exhaustiveCheck } from '@shared/utils/exhaustive-check';
import { ofType } from '@shared/utils/typing-utils';
import { clamp, cloneDeep, defer, isEqual } from 'lodash';
import * as maplibregl from 'maplibre-gl';
import { Subject, filter, takeUntil } from 'rxjs';
import { AppMonitorService } from 'src/app/services/app-monitor.service';
import { ChartService } from 'src/app/services/chart.service';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { AnalysisHttpService, BandwidthsFeatureCollection, PolygonsFeatureCollection } from 'src/app/services/http/analysis-http.service';
import { GeographyHttpService } from 'src/app/services/http/geography-http.service';
import { MessageService } from 'src/app/services/message.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { JourneysOrPersons, ToggleJourneysCountsService } from 'src/app/services/toggle-journeys-counts.service';
import { CustomEventsConstants } from 'src/app/utils/constants/custom-events-constants';
import { LocalStorageConstants } from 'src/app/utils/constants/local-storage-constants';
import { MapStyle } from 'src/app/utils/constants/map-style';
import { LocalSpinner } from 'src/app/utils/local-spinner';
import { MapUtils } from 'src/app/utils/map-utils';
import { delayObservable } from 'src/app/utils/rxjs-utils';
import * as S from '../../helpers/map/expression-utils/maplibre-style-expression-utils';

export enum LayerType {
  BACKGROUND_NETHERLANDS_PDOK = 'BACKGROUND_NETHERLANDS_PDOK',
  BANDWIDTHS = 'BANDWIDTHS',
  ORIGIN_POLYGONS_MUNICIPALITY = 'ORIGIN_POLYGONS_MUNICIPALITY',
  ORIGIN_POLYGONS_DISTRICT = 'ORIGIN_POLYGONS_DISTRICT',
  ORIGIN_POLYGONS_NEIGHBORHOOD = 'ORIGIN_POLYGONS_NEIGHBORHOOD',
  ORIGIN_POLYGONS_PC4 = 'ORIGIN_POLYGONS_PC4',
  ORIGIN_POLYGONS_CUSTOM_REGION = 'ORIGIN_POLYGONS_CUSTOM_REGION',
  DESTINATION_POLYGONS_MUNICIPALITY = 'DESTINATION_POLYGONS_MUNICIPALITY',
  DESTINATION_POLYGONS_DISTRICT = 'DESTINATION_POLYGONS_DISTRICT',
  DESTINATION_POLYGONS_NEIGHBORHOOD = 'DESTINATION_POLYGONS_NEIGHBORHOOD',
  DESTINATION_POLYGONS_PC4 = 'DESTINATION_POLYGONS_PC4',
  DESTINATION_POLYGONS_CUSTOM_REGION = 'DESTINATION_POLYGONS_CUSTOM_REGION',
}

export enum PanelType {
  KPI = 'KPI',
  MOVEMENTS = 'MOVEMENTS',
  ORIGINS_AND_DESTINATIONS = 'ORIGINS_AND_DESTINATIONS',
  OBJECTIVES = 'OBJECTIVES',
  MODE_CHAIN = 'MODE_CHAIN',
  TREND_GROUPED_MODES = 'TREND_GROUPED_MODES',
  TREND = 'TREND',
  PANELISTS_HOUSEHOLDS = 'PANELISTS_HOUSEHOLDS',
  PANELISTS_URBANISATION = 'PANELISTS_URBANISATION'
}

interface LayerStyling {
  transparency: number;
  labels?: boolean;
  scaleFactor?: number;
}

@Component({
  selector: 'app-nvp-analysis-map',
  templateUrl: './nvp-analysis-map.component.html',
  styleUrls: ['./nvp-analysis-map.component.scss'],
  providers: [CrossFilteringService, ToggleJourneysCountsService, ChartService]
})

export class NvpAnalysisMapComponent implements AfterViewInit, OnInit, OnDestroy {

  private static readonly INITIAL_POLLING_INTERVAL_MS = 500;
  private static readonly MAX_POLLING_INTERVAL_MS = 3_000;
  private static readonly MAX_BANDWIDTH_WIDTH_METERS = 250;
  private static readonly AVG_ZOOMLEVEL_IN_NL_WHERE_1PX_IS_1M: number =
    // 52: average latitude in the netherlands
    // 256: tile size
    // 6378137: equatorial circumference of the Earth (uses same reference geoid as used by OpenStreetMap)
    Math.log(Math.cos(52 * (Math.PI / 180)) / (256 / (2 * Math.PI * 6378137))) / Math.log(2);

  public readonly LayerType = LayerType;

  public readonly polygonLayers: {
    layerType: LayerType,
    type: OriginOrDestination,
    level: PolygonLevel,
    visible: boolean;
  }[] = [
      { layerType: LayerType.ORIGIN_POLYGONS_MUNICIPALITY, type: 'origin', level: 'gemeente', visible: true },
      { layerType: LayerType.ORIGIN_POLYGONS_DISTRICT, type: 'origin', level: 'wijk', visible: true },
      { layerType: LayerType.ORIGIN_POLYGONS_NEIGHBORHOOD, type: 'origin', level: 'buurt', visible: true },
      { layerType: LayerType.ORIGIN_POLYGONS_PC4, type: 'origin', level: 'pc4', visible: true },
      { layerType: LayerType.ORIGIN_POLYGONS_CUSTOM_REGION, type: 'origin', level: 'custom_regions', visible: false },
      { layerType: LayerType.DESTINATION_POLYGONS_MUNICIPALITY, type: 'destination', level: 'gemeente', visible: true },
      { layerType: LayerType.DESTINATION_POLYGONS_DISTRICT, type: 'destination', level: 'wijk', visible: true },
      { layerType: LayerType.DESTINATION_POLYGONS_NEIGHBORHOOD, type: 'destination', level: 'buurt', visible: true },
      { layerType: LayerType.DESTINATION_POLYGONS_PC4, type: 'destination', level: 'pc4', visible: true },
      { layerType: LayerType.DESTINATION_POLYGONS_CUSTOM_REGION, type: 'destination', level: 'custom_regions', visible: false }
    ];

  public readonly LEGEND_ORIGIN_POLYGON_GRADIENT_CSS =
    'linear-gradient(to right, ' +
    MapStyle.ORIGIN_POLYGON_COLORS.map((color, idx, arr) => `${color} ${(idx / arr.length) * 100}% ${((idx + 1) / arr.length) * 100}%`).join(', ') +
    ')';
  public readonly LEGEND_DESTINATION_POLYGON_GRADIENT_CSS =
    'linear-gradient(to right, ' +
    MapStyle.DESTINATION_POLYGON_COLORS.map((color, idx, arr) => `${color} ${(idx / arr.length) * 100}% ${((idx + 1) / arr.length) * 100}%`)
      .join(', ') +
    ')';

  @HostBinding('style.--left-sidebar-width') public readonly leftSidebarWidth = '300px';
  @HostBinding('style.--right-sidebar-width') public readonly rightSidebarWidth = '500px';

  public leftSidebarCollapsed = false;
  public rightSidebarCollapsed = false;

  public analysis: Analysis | null = null;

  public activeToggle: JourneysOrPersons;
  public groupBackgroundActive = true;
  public groupOriginActive = true;
  public groupDestinationActive = false;
  public groupRoutesActive = false;
  public activeOriginLayer: PolygonLevel = 'gemeente';
  public activeDestinationLayer: PolygonLevel = 'gemeente';

  public layerSpinners: { [key in LayerType]: LocalSpinner };
  public layerControlsExpanded: { [key in LayerType]: boolean };
  public layerDefaultStyling: { [key in LayerType]: LayerStyling };
  public layerStyling: { [key in LayerType]: LayerStyling };

  private readonly BANDWIDTHS_SOURCE_NAME = 'bandwidths-source';
  private readonly CREATE_EMPTY_GEOJSON = () => ({ type: 'FeatureCollection', features: [] } as GeoJSON.FeatureCollection);
  private readonly DEFAULT_BACKGROUND_TRANSPARENCY = 0;
  private readonly DEFAULT_BANDWIDTHS_TRANSPARENCY = 0;
  private readonly DEFAULT_POLYGONS_TRANSPARENCY = 0.2;
  private readonly DEFAULT_BANDWIDTHS_SCALE_FACTOR = 1;

  private analysisId: number;
  private map: maplibregl.Map;
  private layerVisibilityChangedSubject = new Subject<{ layerType: LayerType, visibility: 'visible' | 'none'; }>();
  private fetchedLayersData: Set<LayerType> = new Set();
  private hasBoundsBeenFitted = false;
  private bandwidthsMaxValue = 0;

  @ViewChild('map', { static: true }) private mapDivElemRef: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appMonitorService: AppMonitorService,
    private analysisHttpService: AnalysisHttpService,
    private geographyHttpService: GeographyHttpService,
    private crossFilteringService: CrossFilteringService,
    private spinnerService: SpinnerService,
    private toggleJourneysCountsService: ToggleJourneysCountsService,
    private messageService: MessageService
  ) {
    this.route.params.subscribe((params) => {
      this.analysisId = Number(params['id']);
    });

    this.layerSpinners = Object.fromEntries(Object.values(LayerType)
      .map(layerType => [layerType, new LocalSpinner()])) as { [key in LayerType]: LocalSpinner };
    this.layerControlsExpanded = Object.fromEntries(Object.values(LayerType)
      .map(layerType => [layerType, false])) as { [key in LayerType]: boolean };
    this.layerDefaultStyling = Object.fromEntries(Object.values(LayerType)
      .map(layerType => [layerType, this.getDefaultStyling(layerType)])) as { [key in LayerType]: LayerStyling };

    this.loadLayerControlsStatus();

    this.crossFilteringService.filterOptionsChanged.pipe(takeUntilDestroyed()).subscribe(() => {
      // `defer` makes sure `filterOptionsChanged` is handled by all listeners first -- most notably,
      // cancelling existing layer calls. If not doing this, `fetchVisibleLayersDataIfNeeded` would
      // not try the layer again, because it would think it was already running.
      defer(() => this.fetchVisibleLayersDataForced());
    });

    this.geographyHttpService.getCustomRegionCount().subscribe(count => {
      this.polygonLayers.filter(layer => layer.level === 'custom_regions').forEach(layer => layer.visible = count > 0);
    });
  }

  public ngAfterViewInit() {
    this.map = new maplibregl.Map({
      container: this.mapDivElemRef.nativeElement,
      attributionControl: false,
      style: this.getStyle(),
      center: MapStyle.DEFAULT_MAP_COORDINATES,
      zoom: MapStyle.DEFAULT_MAP_ZOOMLEVEL
    });

    // Set padding to the bit the sidebar hides, so fitToBounds does not consider the space behind the Sidebars
    this.map.setPadding({ left: 300, right: 500, top: 0, bottom: 0 });

    this.map.once('styledata', () => {
      this.refreshAllLayersVisibility();
      this.refreshLayerTransparencyAndLabels(LayerType.BACKGROUND_NETHERLANDS_PDOK);
      this.fetchInitialAnalysisData();
      this.appMonitorService.recordEvent(CustomEventsConstants.OPEN_ANALYSIS);
    });

    this.map.addControl(new maplibregl.ScaleControl({ unit: 'metric' }), 'bottom-left');
    this.map.addControl(new maplibregl.AttributionControl({ compact: false }), 'bottom-left');
    this.map.addControl(new maplibregl.NavigationControl(), 'bottom-left');
  }

  public ngOnInit() {
    this.toggleJourneysCountsService.toggleJourneysOrPersonsChanged.subscribe((activeToggle) => {
      this.activeToggle = activeToggle;
      this.refreshAllDataLayersStyling();
    });
  }

  public ngOnDestroy() {
    this.map?.remove();
  }

  public onLayerChange() {
    this.logActiveLayersToAppMonitor();
    this.fetchVisibleLayersDataIfNeeded();
    this.refreshAllLayersVisibility();
  }

  public onGroupActiveChange(event: MatCheckboxChange, expansionPanel: MatExpansionPanel) {
    if (event.checked && !expansionPanel.expanded) {
      expansionPanel.open();
    } else if (!event.checked && expansionPanel.expanded) {
      expansionPanel.close();
    }
    this.onLayerChange();
  }

  public setBandwidthsScaleFactor(scaleFactor: number) {
    this.layerStyling[LayerType.BANDWIDTHS].scaleFactor = scaleFactor;
    this.refreshBandwidthsScaleFactor();
    this.saveLayerControlsStatus();
    this.appMonitorService.recordEventDebounced5s(CustomEventsConstants.BANDWIDTHS_SIZE_CHANGED, { scaleFactor });
  }

  public setTransparency(layerType: LayerType, transparency: number) {
    this.layerStyling[layerType].transparency = transparency;
    this.refreshLayerTransparencyAndLabels(layerType);
    this.saveLayerControlsStatus();
    this.appMonitorService.recordEventDebounced5s(CustomEventsConstants.TRANSPARENCY_CHANGED, { layerType, transparency });
  }

  public setLabels(layerType: LayerType, labels: boolean) {
    this.layerStyling[layerType].labels = labels;
    this.refreshLayerTransparencyAndLabels(layerType);
    this.saveLayerControlsStatus();
    this.appMonitorService.recordEventDebounced5s(CustomEventsConstants.LABEL_VISIBILITY_CHANGED, { layerType, labels });
  }

  public isLayerControlsDifferentFromDefault(layerType: LayerType) {
    return !isEqual(this.layerStyling[layerType], this.layerDefaultStyling[layerType]);
  }

  public toggleLayerControlsExpanded(layerType: LayerType) {
    this.layerControlsExpanded[layerType] = !this.layerControlsExpanded[layerType];
  }

  private logActiveLayersToAppMonitor() {
    const activeLayers = this.polygonLayers.filter(layer => this.isPolygonLayerVisible(layer)).map(layer => `${layer.layerType}`);
    if (this.groupRoutesActive) {
      activeLayers.push(LayerType.BANDWIDTHS);
    }
    if (this.groupBackgroundActive) {
      activeLayers.push(LayerType.BACKGROUND_NETHERLANDS_PDOK);
    }
    this.appMonitorService.recordEventDebounced5s(CustomEventsConstants.CHANGE_LAYER, {
      activeLayers,
      filters: this.crossFilteringService.getCrossFilterOptions()
    });
  }

  private fetchInitialAnalysisData() {
    this.analysisHttpService
      .getAnalysisById(this.analysisId)
      .pipe(this.spinnerService.register())
      .subscribe({
        next: result => {
          this.analysis = result;
          this.handleState(this.analysis.state);
        },
        error: () => this.returnToAnalysisList()
      });
  }

  private handleState(state: AnalysisState) {
    switch (state) {
      case 'VALID':
        this.fetchVisibleLayersDataForced();
        break;
      case 'CREATING':
        this.messageService.showErrorSnackBar('SNACKBAR_ANALYSIS_ERROR.CREATING_STATE');
        this.returnToAnalysisList();
        break;
      case 'INVALID':
      case 'ERROR':
        this.messageService.showErrorSnackBar('SNACKBAR_ANALYSIS_ERROR.INVALID_STATE');
        this.returnToAnalysisList();
        break;
      default:
        throw exhaustiveCheck(state);
    }
  }

  private returnToAnalysisList() {
    void this.router.navigate(['/analysis-list']);
  }

  /**
   * Clears the layer data cache and fetches all visible layers again.
   * Needed after e.g. crossfiltering.
   */
  private fetchVisibleLayersDataForced() {
    this.fetchedLayersData.clear();
    this.fetchVisibleLayersDataIfNeeded();
  }

  /**
   * Only fetch layers that are activated/visible, have not yet downloaded the data before, or are currently doing so.
   */
  private fetchVisibleLayersDataIfNeeded() {
    if (this.groupRoutesActive && !this.fetchedLayersData.has(LayerType.BANDWIDTHS) && !this.layerSpinners.BANDWIDTHS.active) {
      this.fetchBandwidthsLayerData();
    }

    const visiblePolygonLayers = this.polygonLayers.filter(layer => this.isPolygonLayerVisible(layer));
    for (const visiblePolygonLayer of visiblePolygonLayers) {
      if (!this.fetchedLayersData.has(visiblePolygonLayer.layerType) && !this.layerSpinners[visiblePolygonLayer.layerType].active) {
        this.fetchPolygonLayerData(visiblePolygonLayer);
      }
    }
  }

  private checkFitBounds(polygonLayer: typeof this.polygonLayers[0]) {
    if (!this.hasBoundsBeenFitted) {
      setTimeout(() => MapUtils.fitBoundsForSource(this.map, this.getPolygonSourceName(polygonLayer)), 500);
      this.hasBoundsBeenFitted = true;
    }
  }

  private isPolygonLayerVisible(layer: typeof this.polygonLayers[0]) {
    return (layer.type === 'origin' && layer.level === this.activeOriginLayer && this.groupOriginActive) ||
      (layer.type === 'destination' && layer.level === this.activeDestinationLayer && this.groupDestinationActive);
  }

  private fetchBandwidthsLayerData(delayMs: number = 0) {
    (this.map.getSource(this.BANDWIDTHS_SOURCE_NAME) as maplibregl.GeoJSONSource).setData(this.CREATE_EMPTY_GEOJSON());
    const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();
    delayObservable(this.analysisHttpService.getBandwidthAnalysisGeoJson(this.analysisId, crossFilterOptions), delayMs)
      .pipe(
        this.layerSpinners[LayerType.BANDWIDTHS].register(),
        takeUntil(this.crossFilteringService.filterOptionsChanged),
        takeUntil(this.layerVisibilityChangedSubject.pipe(filter(v => v.layerType === LayerType.BANDWIDTHS && v.visibility === 'none')))
        // takeUntil applies to the whole polling chain, so it will stop regardless of whether its in fetching or waiting phase
      )
      .subscribe(bandwidthsResponse => {
        this.handleBandwidthsResponse(bandwidthsResponse, delayMs);
      });
  }

  private handleBandwidthsResponse(response: BandwidthsFeatureCollection | 'CREATING', prevDelayMs: number) {
    if (response === 'CREATING') {
      const newDelayMs = prevDelayMs === 0
        ? NvpAnalysisMapComponent.INITIAL_POLLING_INTERVAL_MS
        : Math.min(prevDelayMs * 1.5, NvpAnalysisMapComponent.MAX_POLLING_INTERVAL_MS);
      this.fetchBandwidthsLayerData(newDelayMs); // It was not ready yet. Try again.
    } else {
      this.fetchedLayersData.add(LayerType.BANDWIDTHS);
      (this.map.getSource(this.BANDWIDTHS_SOURCE_NAME) as maplibregl.GeoJSONSource).setData(response);
      this.refreshBandwidthLayerStyling();
    }
  }

  private fetchPolygonLayerData(polygonLayer: typeof this.polygonLayers[0]) {
    (this.map.getSource(this.getPolygonSourceName(polygonLayer)) as maplibregl.GeoJSONSource).setData(this.CREATE_EMPTY_GEOJSON());
    const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();
    this.analysisHttpService
      .getPolygonAnalysisGeoJson(this.analysisId, crossFilterOptions, polygonLayer.type, polygonLayer.level)
      .pipe(
        this.layerSpinners[polygonLayer.layerType].register(),
        takeUntil(this.crossFilteringService.filterOptionsChanged),
        takeUntil(this.layerVisibilityChangedSubject.pipe(filter(v => v.layerType === polygonLayer.layerType && v.visibility === 'none')))
      )
      .subscribe((polygonsGeoJson) => {
        this.fetchedLayersData.add(polygonLayer.layerType);
        (this.map.getSource(this.getPolygonSourceName(polygonLayer)) as maplibregl.GeoJSONSource).setData(polygonsGeoJson);
        this.refreshPolygonLayerStyling(polygonLayer);
        this.checkFitBounds(polygonLayer);
      });
  }


  private refreshAllDataLayersStyling() {
    this.refreshBandwidthLayerStyling();
    this.polygonLayers.forEach(polygonLayer => this.refreshPolygonLayerStyling(polygonLayer));
  }

  private refreshBandwidthLayerStyling() {
    const bandwidthGeoJson = (this.map?.getSource(this.BANDWIDTHS_SOURCE_NAME) as maplibregl.GeoJSONSource)?._data;
    if (bandwidthGeoJson) {
      this.bandwidthsMaxValue = this.getMaximumValue(bandwidthGeoJson as BandwidthsFeatureCollection);
      this.refreshBandwidthsScaleFactor();
      this.refreshLayerTransparencyAndLabels(LayerType.BANDWIDTHS);
      this.map.setLayoutProperty('bandwidths-labels', 'text-field', this.getPropertyName());
    }
  }

  private refreshPolygonLayerStyling(polygonLayer: typeof this.polygonLayers[0]) {
    const polygonGeoJson = (this.map?.getSource(this.getPolygonSourceName(polygonLayer)) as maplibregl.GeoJSONSource)?._data;
    if (polygonGeoJson) {
      const maxValue = this.getMaximumValue(polygonGeoJson as PolygonsFeatureCollection);
      this.map.setPaintProperty(`${polygonLayer.type}-${polygonLayer.level}-polygons`, 'fill-color', this.getPolygonColors(maxValue, polygonLayer.type));
      this.map.setLayoutProperty(`${polygonLayer.type}-${polygonLayer.level}-polygons-labels`, 'text-field', this.getPropertyName());
      this.refreshLayerTransparencyAndLabels(polygonLayer.layerType);
    }
  }

  private refreshBandwidthsScaleFactor() {
    assert(this.layerStyling.BANDWIDTHS.scaleFactor !== undefined);
    const lineWidth = this.getLineWidth(this.bandwidthsMaxValue, this.layerStyling.BANDWIDTHS.scaleFactor);
    this.map.setPaintProperty('bandwidths', 'line-width', lineWidth);
  }

  private refreshLayerTransparencyAndLabels(layerType: LayerType) {
    const opacity = 1 - this.layerStyling[layerType].transparency;
    const labels = this.layerStyling[layerType].labels;
    switch (layerType) {
      case LayerType.BANDWIDTHS:
        this.map.setPaintProperty('bandwidths', 'line-opacity', opacity);
        this.map.setPaintProperty('bandwidths-labels', 'text-opacity', labels ? opacity : 0);
        break;
      case LayerType.BACKGROUND_NETHERLANDS_PDOK:
        this.map.setPaintProperty('background', 'raster-opacity', opacity);
        break;
      default: {
        const polygonLayer = assertNotNull(this.polygonLayers.find(p => p.layerType === layerType) ?? null);
        this.map.setPaintProperty(`${polygonLayer.type}-${polygonLayer.level}-polygons`, 'fill-opacity', opacity);
        this.map.setPaintProperty(`${polygonLayer.type}-${polygonLayer.level}-polygons-outline`, 'line-opacity', opacity);
        this.map.setPaintProperty(`${polygonLayer.type}-${polygonLayer.level}-polygons-labels`, 'text-opacity', labels ? opacity : 0);
      }
    }
  }

  private refreshAllLayersVisibility() {
    const backgroundVisibility = this.groupBackgroundActive ? 'visible' : 'none';
    this.map.setLayoutProperty('background', 'visibility', backgroundVisibility);

    const bandwidthsVisibility = this.groupRoutesActive ? 'visible' : 'none';
    this.map.setLayoutProperty('bandwidths', 'visibility', bandwidthsVisibility);
    this.map.setLayoutProperty('bandwidths-labels', 'visibility', bandwidthsVisibility);
    this.layerVisibilityChangedSubject.next({ layerType: LayerType.BANDWIDTHS, visibility: bandwidthsVisibility });

    for (const polygonLayer of this.polygonLayers) {
      const polygonVisibility = this.isPolygonLayerVisible(polygonLayer) ? 'visible' : 'none';
      this.map.setLayoutProperty(`${polygonLayer.type}-${polygonLayer.level}-polygons`, 'visibility', polygonVisibility);
      this.map.setLayoutProperty(`${polygonLayer.type}-${polygonLayer.level}-polygons-outline`, 'visibility', polygonVisibility);
      this.map.setLayoutProperty(`${polygonLayer.type}-${polygonLayer.level}-polygons-labels`, 'visibility', polygonVisibility);
      this.layerVisibilityChangedSubject.next({ layerType: polygonLayer.layerType, visibility: polygonVisibility });
    }
  }

  public getAnalysisTitle(): string {
    return this.analysis?.title ? ` \u2013 ${this.analysis?.title}` : '';
  }

  private getStyle(): maplibregl.StyleSpecification {
    return {
      ...MapStyle.EMPTY_STYLE,
      sources: this.getSources(),
      layers: [
        MapStyle.DEFAULT_BACKGROUND_LAYER,
        ...this.getPolygonLayers(),
        {
          id: 'bandwidths',
          type: 'line',
          source: this.BANDWIDTHS_SOURCE_NAME,
          layout: {
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#cf5f92'
          }
        },
        {
          id: 'bandwidths-labels',
          type: 'symbol',
          source: this.BANDWIDTHS_SOURCE_NAME,
          minzoom: 13.5,
          layout: {
            'text-field': this.getPropertyName(),
            'text-font': ['Open Sans Regular'],
            'text-size': 11
          },
          paint: {
            'text-color': 'white',
            'text-halo-width': 0.5,
            'text-halo-color': 'black',
            'text-halo-blur': 1
          }
        }
      ]
    };
  }

  private getSources(): { [_: string]: maplibregl.SourceSpecification; } {
    const sources: { [_: string]: maplibregl.SourceSpecification; } = {
      'background-source': MapStyle.DEFAULT_BACKGROUND_SOURCE,
      [this.BANDWIDTHS_SOURCE_NAME]: {
        type: 'geojson',
        data: this.CREATE_EMPTY_GEOJSON()
      }
    };

    this.polygonLayers.forEach(layer => {
      sources[this.getPolygonSourceName(layer)] = {
        type: 'geojson',
        data: this.CREATE_EMPTY_GEOJSON()
      };
    });

    return sources;
  }

  private getPolygonSourceName(layer: typeof this.polygonLayers[0]) {
    return `${layer.type}-${layer.level}-polygons-source`;
  }

  private getPolygonLayers() {
    return this.polygonLayers.flatMap(layer => {
      return [
        ofType<maplibregl.LayerSpecification>({
          id: `${layer.type}-${layer.level}-polygons`,
          type: 'fill',
          source: this.getPolygonSourceName(layer)
        }),
        ofType<maplibregl.LayerSpecification>({
          id: `${layer.type}-${layer.level}-polygons-outline`,
          type: 'line',
          source: this.getPolygonSourceName(layer),
          paint: {
            'line-color': 'rgb(64,64,64)',
            'line-width': 1
          }
        }),
        ofType<maplibregl.LayerSpecification>({
          id: `${layer.type}-${layer.level}-polygons-labels`,
          type: 'symbol',
          source: this.getPolygonSourceName(layer),
          minzoom: this.getPolygonLabelMinZoom(layer.level),
          layout: {
            'text-field': this.getPropertyName(),
            'text-font': ['Open Sans Regular'],
            'text-size': 14
          },
          paint: {
            'text-color': 'black',
            'text-halo-width': 1,
            'text-halo-color': 'white',
            'text-halo-blur': 1
          }
        })
      ];
    });
  }

  private getLineWidth(maximumBandwidthValue: number, scaleFactor: number) {
    return this.getInterpolatedDataExpression(this.getPropertyName(), NvpAnalysisMapComponent.MAX_BANDWIDTH_WIDTH_METERS * scaleFactor / maximumBandwidthValue);
  }

  private getPolygonColors(maximumPolygonFillValue: number, type: OriginOrDestination) {
    return this.getValueRangeColorForPolygon(S.divide(this.getPropertyName(), maximumPolygonFillValue), type);
  }

  private getPropertyName() {
    const propertyName = this.activeToggle === JourneysOrPersons.JOURNEYS ? 'journeys' : 'persons';
    return S.get(propertyName);
  }

  private getPolygonLabelMinZoom(level: PolygonLevel) {
    switch (level) {
      case 'gemeente':
        return 7;
      case 'wijk':
        return 9;
      case 'buurt':
        return 10;
      case 'pc4':
        return 8;
      case 'custom_regions':
        return 6;
      default:
        throw exhaustiveCheck(level);
    }
  }

  private getInterpolatedDataExpression(dataExpression: number | maplibregl.ExpressionSpecification, interpolationScaleFactor: number = 1) {
    const interpolationStartZoomWeight = Math.pow(2, 0 - NvpAnalysisMapComponent.AVG_ZOOMLEVEL_IN_NL_WHERE_1PX_IS_1M);
    const interpolationEndZoomWeight = Math.pow(2, 24 - NvpAnalysisMapComponent.AVG_ZOOMLEVEL_IN_NL_WHERE_1PX_IS_1M);
    return S.interpolateExponential(
      2,
      S.zoom(),
      0,
      S.multiply(dataExpression, interpolationStartZoomWeight * interpolationScaleFactor),
      24,
      S.multiply(dataExpression, interpolationEndZoomWeight * interpolationScaleFactor)
    );
  }

  private getValueRangeColorForPolygon(dataExpression: number | maplibregl.ExpressionSpecification, type: OriginOrDestination): maplibregl.ExpressionSpecification {
    const colors = type === 'origin' ? MapStyle.ORIGIN_POLYGON_COLORS : MapStyle.DESTINATION_POLYGON_COLORS;
    const dataExpressionAsNumber = S.toNumber(dataExpression);
    const caseExpressions = [];
    for (let i = 0; i < colors.length; i++) {
      caseExpressions.push(S.between(dataExpressionAsNumber, i / colors.length, (i + 1) / colors.length, i === 0, true));
      caseExpressions.push(colors[i]);
    }
    caseExpressions.push('black'); // Default case
    return S.switchCase(...caseExpressions);
  }

  private getMaximumValue(sourceGeoJson: BandwidthsFeatureCollection | PolygonsFeatureCollection) {
    const allValues = sourceGeoJson.features.map((feature) => this.activeToggle === JourneysOrPersons.JOURNEYS ?
      feature.properties.journeys : feature.properties.persons);
    return Math.max(...allValues);
  }

  private loadLayerControlsStatus() {
    this.layerStyling = cloneDeep(this.layerDefaultStyling);
    try {
      const layerStyle = JSON.parse(localStorage.getItem(LocalStorageConstants.LAYER_STYLE) as string);
      if (layerStyle && typeof layerStyle === 'object' && !Array.isArray(layerStyle)) {
        Object.values(LayerType).forEach(layerType => this.loadLayerControlsStatusFromJson(layerType, layerStyle));
      }
    } catch (e) {
      console.error(e);
    }
  }

  private loadLayerControlsStatusFromJson(layerType: LayerType, layerStyleJson: Record<string, any>) {
    if (typeof layerStyleJson[layerType]?.transparency === 'number') {
      this.layerStyling[layerType].transparency = clamp(layerStyleJson[layerType].transparency, 0, 0.95);
    }
    if (this.layerStyling[layerType].scaleFactor !== undefined && typeof layerStyleJson[layerType]?.scaleFactor === 'number') {
      this.layerStyling[layerType].scaleFactor = clamp(layerStyleJson[layerType].scaleFactor, 0.1, 10);
    }
    if (this.layerStyling[layerType].labels !== undefined && typeof layerStyleJson[layerType]?.labels === 'boolean') {
      this.layerStyling[layerType].labels = layerStyleJson[layerType].labels;
    }
  }

  private saveLayerControlsStatus() {
    localStorage.setItem(LocalStorageConstants.LAYER_STYLE, JSON.stringify(this.layerStyling));
  }

  private getDefaultStyling(layerType: LayerType): LayerStyling {
    const transparency = this.getDefaultTransparency(layerType);
    const scaleFactor = layerType === LayerType.BANDWIDTHS ? this.DEFAULT_BANDWIDTHS_SCALE_FACTOR : undefined;
    const labels = layerType !== LayerType.BACKGROUND_NETHERLANDS_PDOK ? true : undefined;
    return { transparency, scaleFactor, labels };
  }

  private getDefaultTransparency(layerType: LayerType) {
    switch (layerType) {
      case LayerType.BACKGROUND_NETHERLANDS_PDOK: return this.DEFAULT_BACKGROUND_TRANSPARENCY;
      case LayerType.BANDWIDTHS: return this.DEFAULT_BANDWIDTHS_TRANSPARENCY;
      default: return this.DEFAULT_POLYGONS_TRANSPARENCY;
    }
  }
}
