<app-main-page-layout [pageLabel]="'GLOBAL.NVP_ANALYZER' | translate" [styledBackground]="true" [footer]="true">

  <div class="main-page-layout">
    <div class="analysis-count"><span> {{numberOfAnalyses}} / {{MAX_NUMBER_OF_ANALYSES}} {{"ANALYSIS_LIST.ANALYSES" |
        translate}}</span></div>
    <div class="compare-link-div"> <a routerLink="/analysis-compare"> {{"ANALYSIS_LIST.COMPARE_PAGE_LINK" |
        translate}}</a></div>
    <div class="create-analysis-div">
      @if(this.numberOfAnalyses < MAX_NUMBER_OF_ANALYSES) { <a routerLink="/analysis-config">
        <button class="create-analysis-button" data-cy="create-analysis"
          matTooltip="{{ 'ANALYSIS_LIST.CREATE' | translate }}" mat-flat-button color="accent">
          <mat-icon>add</mat-icon> {{ 'ANALYSIS_LIST.CREATE_TOOLTIP' | translate }}
        </button>
        </a>
        } @else {
        <button class="create-analysis-button" data-cy="create-analysis"
          matTooltip="{{ 'ANALYSIS_LIST.CREATE' | translate }}" mat-flat-button color="accent"
          (click)="this.triggerMaximumAnalysesError()">
          <mat-icon>add</mat-icon> {{ 'ANALYSIS_LIST.CREATE_TOOLTIP' | translate }}
        </button>
        }
    </div>

    <div class="container">
      <div class="table-container mat-elevation-z2">
        <div class="table-div">
          @if(!analysisListLoaded){
          <ngx-skeleton-loader [theme]="{ 'border-radius': '0', height: '50px' }" count="5">
          </ngx-skeleton-loader>
          } @else {
          <table data-cy="analysis-table" mat-table [dataSource]="dataSource" [trackBy]="trackByAnalysisId" matSort>
            <ng-container matColumnDef="name">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'ANALYSIS_LIST.LABEL_NAME' | translate }}</th>
              <td mat-cell *matCellDef="let analysis; let rowIndex = index">
                <div>
                  @if (analysis.state === 'VALID') {
                  <a [attr.data-cy]="'view-analysis-' + rowIndex" routerLink="/analysis/{{analysis.analysisId}}">
                    {{ analysis.title }}
                  </a>
                  } @else {
                  <span>{{ analysis.title }}</span>
                  }
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="projectCode">
              <th mat-header-cell *matHeaderCellDef>{{ 'ANALYSIS_LIST.LABEL_CODE' | translate }}</th>
              <td mat-cell *matCellDef="let analysis">
                <div class="project-code-cell">
                  <div class="project-code">{{ analysis.projectCode }}</div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'ANALYSIS_LIST.LABEL_STATE' | translate }}</th>
              <td mat-cell *matCellDef="let analysis">
                <div [class.analysis-state-warning]="analysis.state === 'CREATING'"
                  [class.analysis-state-error]="analysis.state === 'INVALID'">{{ 'ANALYSIS_LIST.STATE.' +
                  analysis.state | translate }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="created">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'ANALYSIS_LIST.LABEL_CREATED' | translate }}</th>
              <td mat-cell *matCellDef="let analysis">
                <div>
                  {{ analysis.creationStart | date:'dd-MM-yyyy HH:mm' }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="persons">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'ANALYSIS_LIST.LABEL_PERSONS' | translate }}</th>
              <td mat-cell *matCellDef="let analysis">
                <div> {{analysis.persons | number }} </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="journeys">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'ANALYSIS_LIST.LABEL_JOURNEYS' | translate }}
              </th>
              <td mat-cell *matCellDef="let analysis">
                <div> {{analysis.journeys | number }} </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let analysis; let rowIndex = index">
                <div class="analysis-actions">
                  <a [attr.data-cy]="'edit-analysis-' + analysis.projectCode" mat-icon-button
                    routerLink="/analysis-config/{{analysis.analysisId}}" [disabled]="analysis.state === 'CREATING'"
                    matTooltip="{{ 'ANALYSIS_LIST.EDIT' | translate }}">
                    <mat-icon>edit</mat-icon>
                  </a>
                  @if (numberOfAnalyses < MAX_NUMBER_OF_ANALYSES) { <a
                    [attr.data-cy]="'duplicate-analysis-' + analysis.projectCode" mat-icon-button
                    routerLink="/analysis-config" [queryParams]="{basedOnId: analysis.analysisId}"
                    matTooltip="{{ 'ANALYSIS_LIST.DUPLICATE' | translate }}">
                    <mat-icon>content_copy</mat-icon>
                    </a>
                    } @else {
                    <a [attr.data-cy]="'duplicate-analysis-' + analysis.projectCode" mat-icon-button
                      (click)="this.triggerMaximumAnalysesError()" (keyup.enter)="this.triggerMaximumAnalysesError()"
                      matTooltip="{{ 'ANALYSIS_LIST.DUPLICATE' | translate }}" role="link" aria-hidden="true"
                      tabIndex="0">
                      <mat-icon>content_copy</mat-icon>
                    </a>
                    }
                    <button [attr.data-cy]="'delete-analysis-' + analysis.projectCode" mat-icon-button
                      matTooltip="{{ 'ANALYSIS_LIST.DELETE' | translate }}" (click)="deleteAnalysis(analysis)"
                      [disabled]="analysis.state === 'CREATING'">
                      <mat-icon>delete</mat-icon>
                    </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row [class.alt-row]="odd" *matRowDef="let row; let odd = odd;columns: displayedColumns; "></tr>
          </table>
          @if(numberOfAnalyses === 0){
          <div class="no-analysis">
            {{"ANALYSIS_LIST.NO_ANALYSIS" | translate}}
          </div>
          }
          }
        </div>
      </div>
    </div>
  </div>
</app-main-page-layout>
