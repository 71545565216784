import { Component } from '@angular/core';
import { Analysis } from '@shared/resources/analysis/analysis';
import { assert } from '@shared/utils/assert';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-nvp-analysis-compare',
  templateUrl: './nvp-analysis-compare.component.html',
  styleUrl: './nvp-analysis-compare.component.scss'
})
export class NvpAnalysisCompareComponent {

  private static readonly INITIAL_NUM_COLUMNS = 2;

  public readonly NUMBER_OF_COLUMNS_OPTIONS = [2, 3, 4];

  public numberOfColumns = NvpAnalysisCompareComponent.INITIAL_NUM_COLUMNS;
  public columns: unknown[] = Array(this.numberOfColumns);
  public analysisList: Analysis[] = [];

  constructor(
    private analysisHttpService: AnalysisHttpService,
    private spinnerService: SpinnerService
  ) {
    this.analysisHttpService.getAnalysisList()
      .pipe(this.spinnerService.register())
      .subscribe(analysisList => {
        this.analysisList = analysisList.filter(analysis => analysis.state === 'VALID');
      });
  }

  public setNumberOfColumns(numberOfColumns: number) {
    assert(this.NUMBER_OF_COLUMNS_OPTIONS.includes(numberOfColumns));
    this.numberOfColumns = numberOfColumns;
    this.columns = Array(this.numberOfColumns);
  }
}
