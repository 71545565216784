import { Component, Input } from '@angular/core';
import { DateTimeFilter } from '@shared/resources/analysis/datetimefilter/date-time-filter';
import { DateTimeFilterEntry } from '@shared/resources/analysis/datetimefilter/date-time-filter-entry';
import { DateTimeFilterEntryUtils } from '@shared/utils/date-time-filter-entry-utils';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-date-time-filter',
  templateUrl: './date-time-filter.component.html',
  styleUrl: './date-time-filter.component.scss'
})

export class DateTimeFilterComponent {

  @Input({ required: true }) public dateTimeFilter: DateTimeFilter;

  public isTrialUser = false;

  constructor(authService: AuthService) {
    this.isTrialUser = authService.isTrialUser;
  }

  public onAddDateAndTimeFilterClick() {
    const dateTimeFilter: DateTimeFilterEntry = {
      ...DateTimeFilterEntryUtils.getMostRecentYearRange(),
      startTime: 0,
      endTime: 23,
      daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
    };
    this.dateTimeFilter.entries.push(dateTimeFilter);
  }

  public onDateTimeFilterEntryToBeDeleteEvent(dateTimeFilterEntryToBeDeleted: DateTimeFilterEntry) {
    const index = this.dateTimeFilter.entries.findIndex(entry => entry === dateTimeFilterEntryToBeDeleted);
    this.dateTimeFilter.entries.splice(index, 1);
  }
}
