import { Component } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-no-license',
  templateUrl: './no-license.component.html',
  styleUrl: './no-license.component.scss'
})
export class NoLicenseComponent {

  constructor(private authService: AuthService) { }

  public logout() {
    this.authService.logoff();
    return false; // Needed to stop default link target #; otherwise logoff doesn't happen
  }
}
