import { DateTimeFilter } from '@shared/resources/analysis/datetimefilter/date-time-filter';
import { DaysOfWeek } from '@shared/resources/analysis/days-of-week';

export interface DateTimeFilterOverviewEntry {
  dayKeys: string[],
  startDate: Date;
  endDate: Date;
  startTime: number;
  endTime: number;
}

export class DateTimeFilterOverviewGenerator {

  public generate(dateTimeFilter: DateTimeFilter) {
    return dateTimeFilter.entries.map(entry => {
      return {
        dayKeys: this.getDayKeys(entry.daysOfWeek),
        startDate: entry.startDate,
        endDate: entry.endDate,
        startTime: entry.startTime,
        endTime: entry.endTime
      };
    });
  }

  private getDayKeys(daysOfWeek: DaysOfWeek[]) {
    if (this.isWeekdayOnly(daysOfWeek)) {
      return ['DATE_TIME_FILTER.WEEKDAYS'];
    } else if (this.isWeekendOnly(daysOfWeek)) {
      return ['DATE_TIME_FILTER.WEEKENDS'];
    } else {
      return this.localizeDays(daysOfWeek);
    }
  }

  private isWeekdayOnly(days: DaysOfWeek[]): boolean {
    const weekdays: DaysOfWeek[] = [
      DaysOfWeek.MONDAY,
      DaysOfWeek.TUESDAY,
      DaysOfWeek.WEDNESDAY,
      DaysOfWeek.THURSDAY,
      DaysOfWeek.FRIDAY
    ];

    return days.length === weekdays.length && days.every(day => weekdays.includes(day));
  }


  private isWeekendOnly(days: DaysOfWeek[]): boolean {
    const weekends: DaysOfWeek[] = [
      DaysOfWeek.SATURDAY,
      DaysOfWeek.SUNDAY
    ];

    return days.length === weekends.length && days.every(day => weekends.includes(day));
  }


  private localizeDays(days: DaysOfWeek[]) {
    return days.map(day => `DATE_TIME_FILTER.${DaysOfWeek[day]}`);
  }

}
