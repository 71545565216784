import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { TransportModeGroupType } from '@shared/resources/analysis/transport-mode-group-type';
import { TrendPerModeGroupResponse } from '@shared/resources/analysis/trend-per-mode-group-response';
import * as echarts from 'echarts';
import { takeUntil } from 'rxjs';
import { ChartService } from 'src/app/services/chart.service';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { Constants } from 'src/app/utils/constants/constants';
import { GraphStyle } from 'src/app/utils/constants/graph-style';
import { LocalSpinner } from 'src/app/utils/local-spinner';
import { PanelType } from '../nvp-analysis-map/nvp-analysis-map.component';

@Component({
  selector: 'app-trends-by-modegroup',
  templateUrl: './trends-by-modegroup.component.html',
  styleUrl: './trends-by-modegroup.component.scss'
})
export class TrendsByModegroupComponent implements OnInit {

  public static readonly LINE_WIDTH = 1;

  public readonly HEIGHT_PX = 280;
  public readonly LOADER_COUNT = Math.floor((this.HEIGHT_PX - 20) / 36);

  @Input() public analysisId: number;

  public spinner = new LocalSpinner();

  private panelType: PanelType = PanelType.TREND_GROUPED_MODES;
  private echartsInstance: echarts.ECharts;
  private modegroupTrendResponse: TrendPerModeGroupResponse['counts'] = [];

  @ViewChild('chart', { static: true }) private chart: ElementRef;

  constructor(
    private analysisHttpService: AnalysisHttpService,
    private crossFilteringService: CrossFilteringService,
    private translateService: TranslateService,
    private chartService: ChartService
  ) {
    this.crossFilteringService.filterOptionsChanged.pipe(takeUntilDestroyed()).subscribe(() => {
      this.fetchTrendsByModegroup();
    });

    this.chartService.exportClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.exportChart(this.echartsInstance).catch(e => console.error(e));
      }
    });

    this.chartService.copyClipboardClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.copyChartToClipboard(this.echartsInstance).catch(e => console.error(e));
      }
    });

    this.chartService.exportCsvClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        const fileName = this.translateService.instant('ANALYSIS_OVERVIEW.PANEL.TITLE.TREND_GROUPED_MODES');
        this.chartService.exportDataToCsv(fileName, this.modegroupTrendResponse);
      }
    });
  }

  public ngOnInit() {
    this.fetchTrendsByModegroup();
  }

  private fetchTrendsByModegroup() {
    const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();

    this.analysisHttpService.getModeGroupTrend(this.analysisId, crossFilterOptions)
      .pipe(this.spinner.register(), takeUntil(this.crossFilteringService.filterOptionsChanged))
      .subscribe(journeysCount => {
        this.modegroupTrendResponse = journeysCount.counts;
        this.initChartOnlyOnce();
        this.echartsInstance.setOption(this.getChartOptions());
      });
  }

  private initChartOnlyOnce() {
    if (!this.echartsInstance) {
      this.echartsInstance = echarts.init(this.chart.nativeElement);
      this.echartsInstance.on('mousemove', () => this.echartsInstance.getZr().setCursorStyle('default'));
    }
  }

  private getChartOptions() {
    return {
      xAxis: {
        type: 'time',
        ...GraphStyle.AXIS_STYLE
      },
      yAxis: {
        ...GraphStyle.AXIS_STYLE
      },
      ...GraphStyle.TEXT_STYLE,
      grid: {
        left: 40,
        right: 15,
        top: 5,
        bottom: 20
      },
      series: [
        {
          name: this.getModegroupTranslation('PUBLIC_TRANSPORT'),
          data: this.getChartData('PUBLIC_TRANSPORT'),
          type: 'line',
          color: Constants.COLORS_PER_MODE.TRAIN,
          showSymbol: false,
          lineStyle: {
            width: TrendsByModegroupComponent.LINE_WIDTH
          }
        },
        {
          name: this.getModegroupTranslation('FOOT'),
          data: this.getChartData('FOOT'),
          type: 'line',
          color: Constants.COLORS_PER_MODE.FOOT,
          showSymbol: false,
          lineStyle: {
            width: TrendsByModegroupComponent.LINE_WIDTH
          }
        },
        {
          name: this.getModegroupTranslation('BIKE'),
          data: this.getChartData('BIKE'),
          type: 'line',
          color: Constants.COLORS_PER_MODE.BIKE,
          showSymbol: false,
          lineStyle: {
            width: TrendsByModegroupComponent.LINE_WIDTH
          }
        },
        {
          name: this.getModegroupTranslation('CAR'),
          data: this.getChartData('CAR'),
          type: 'line',
          color: Constants.COLORS_PER_MODE.CAR,
          showSymbol: false,
          lineStyle: {
            width: TrendsByModegroupComponent.LINE_WIDTH
          }
        }
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        ...TrendsByModegroupComponent.getTooltipFormatter(),
        ...GraphStyle.TOOLTIP_STYLE
      }
    };
  }

  private getChartData(type: TransportModeGroupType) {
    const allDates = new Set(this.modegroupTrendResponse.map(record => record.date));
    const result = Array.from(allDates.values()).map(date => [
      date,
      this.modegroupTrendResponse.find(record => record.modeGroup === type && record.date === date)?.avgJourneysPerPerson ?? 0
    ]);
    return result;
  }

  public static getTooltipFormatter() {
    return {
      formatter: function (params: any) {
        const date = `<b>${params[0].data[0]}</b></br>`;
        const labels = (params as any[]).reverse().map(param =>
          `<div style="display: inline-block; width: 9px; height: 9px; background-color: ${param.color}; border-radius: 50%; margin-right: 5px;"></div>
          <b>${param.seriesName}:</b> ${param.data[1].toFixed(2)}`
        ).join('</br>');
        return date.concat(labels);
      }
    };
  }

  private getModegroupTranslation(key: TransportModeGroupType) {
    return this.translateService.instant(`ANALYSIS_OVERVIEW.DIAGRAMS.TREND_GROUPED_MODES.${key}`);
  }
}
